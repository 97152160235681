// src/components/Product.tsx
import React, {useEffect, useState} from 'react';
import blender from '../images/blender.jpg';
import { firestore } from '../FirebaseConfig'; 
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot, doc, getDoc, query, where } from "firebase/firestore";
import { time } from 'console';
// Definiamo il tipo delle props per il componente Product
interface ProductProps {
  id: string,
  timestamp: string,
  transactionId: string,
  onClick: () => void;
}

interface info { 
  id: string,
  Categoria: string,
  Nome: string,
}

const Order: React.FC<ProductProps> = ({ id, timestamp, transactionId, onClick}) => {
const [info, setinfo] = useState<info | null>(null);







useEffect(() => {

  const docRef = doc(firestore, "Modelli", id);

  // Ascolta i cambiamenti in tempo reale nella collezione "Modelli"
   getDoc(docRef).then((docSnap) => {
    if (docSnap.exists()) {
      const doc = docSnap; // Ottieni il primo documento trovato

      // Imposta l'oggetto info con i dati del documento
      setinfo({
        id: doc.id,
        Categoria: doc.data()?.Categoria || "Categoria non disponibile",
        Nome: doc.data()?.Nome || "Nome non disponibile",
      });
    } else {
      setinfo(null); // Se non ci sono documenti, imposta info a null
    }
  }, (error) => {
    console.error("Errore nell'ascoltare i modelli:", error);
  });

  // Cleanup: chiude la connessione quando il componente viene smontato
  return 
}, [id]);



  return (
    <div>
<button className='flex flex-col' onClick={onClick}>
<div className='flex flex-row justify-center text-[0.8rem]  py-1 font-thin'>
{info ? (
          <>
            <p className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem]  text-center'>{info.Nome}</p>
            <p className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem] text-center'>{info.Categoria}</p>
            <p className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem] text-center'>{timestamp}</p>
            <p className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem] text-green-300 text-center'>Completed</p>
          </>
        ) : (
          <p className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem] text-center'>Loading...</p> // Messaggio di caricamento
        )}
</div>

</button>
<div className='flex pt-1 sm:pt-0  px-4 items-center '>
<div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '>
</div>
</div>
</div>
  );
};

export default Order;
