import React, {useEffect, useState} from 'react';
import '../styles.css';
import { motion } from "framer-motion";
import '../App.css';
import { Link } from 'react-router-dom';
import {  doc, setDoc, getDocs, collection, query, where } from "firebase/firestore";
import logo from "../images/Frame 6.png"
import { useNavigate } from 'react-router-dom';
import { auth, firestore} from '../FirebaseConfig';
import blender from '../images/blender.jpg'; // Importa l'immagine
import santa from '../images/Santa.png'; // Importa l'immagine
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from '../objects/Header';










const Cancel: React.FC = () => {
  const navigate = useNavigate();
 

   const checkPendingOrder = async () => {
        try {
          const uid = auth.currentUser?.uid; // Ottieni l'ID dell'utente attualmente autenticato
          if (!uid) {
           navigate("/login")
            return;
          }
  
            navigate("/createorder")
            
        } catch (error) {
          console.error("Errore durante la verifica degli ordini:", error);
        }
      };
  

const logout =  async ()  => {

signOut(auth);
}




  return (
    <div className='flex flex-col h-screen  overflow-auto bg-orders  scrollbar-custom '  >
      <Elements stripe={loadStripe(process.env.REACT_APP_STRIPECLIENT? process.env.REACT_APP_STRIPECLIENT: "")}>
  <Header/>
  </Elements>
  
    {/* Scritta iniziale */}
    <div className='flex flex-col mb-14 flex-1  items-center     justify-center  '>
 

  

 <p className=' sm:text-[3.2rem] text-[2rem] sm:leading-[3rem] leading-[2rem]  font-semibold  text-gradient    font-inter  '>
Cancelled
 </p>

 <p className='sm:text-[0.7rem] text-[0.5rem]   font-inter  max-w-[40rem] st  text-center     text-[#A39C96]'>
 Your payment was not successful. No charges have been made, and the transaction has not been completed. If you need assistance or would like to try again, please feel free to do so. Thank you for your understanding.
 </p>

 <div className='flex    pt-5  '>
  
 <motion.div
      whileHover={{ scale: 1.05 }} // Quando si passa sopra, l'elemento aumenta di dimensione
      transition={{ type: "spring", stiffness: 400, damping: 50 }}
    >

       <button  onClick={() => navigate("/")}  className='bg-[#A47040] rounded-[6px] text-[0.7rem] px-8 mr-2 text-center text-white  py-[0.5rem]' >Back to home</button>
    </motion.div>
 
 </div>




 
    </div>



    <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
       © 2025 Giangibuilds from Gianluigi Izzo
    </div>
    </div>
  );
}

export default Cancel;