import React, {useEffect, useState} from 'react';
import '../styles.css';
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore'
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { motion } from "framer-motion";
import '../App.css';
import visa from '../images/Visa.png'; // Importa l'immagine
import master from '../images/mastercard.png'; // Importa l'immagine
import paypal from '../images/paypal.png'; // Importa l'immagine
import discover from '../images/disover.png'; // Importa l'immagine
import unionnp from '../images/unionp.png'; // Importa l'immagine
import { storage } from "../FirebaseConfig";
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { auth, firestore} from '../FirebaseConfig';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStripe, useElements} from '@stripe/react-stripe-js';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo, faArrowLeft, faSearch  } from '@fortawesome/free-solid-svg-icons';
interface ModelD {
  id: string,
  Titolo: string,
  descrizione: string,
  categoria: string,
  createdat: string,
  state: string,
  Prezzo: number,
  notes: string,
  realstate: number,
  immagini: string[]
}
interface PaymentStatus {
  status: 'IDLE' | 'PENDING' | 'COMPLETED' | 'ERROR';
}
const Myorders: React.FC = () => {
  const navigate = useNavigate();
    const elements = useElements();
   const stripe = useStripe();
  const [loading, setLoading] = useState(false);

  const [menu, setmenu] = useState(false);
  const [selectedcard, setselectedcard] = useState("Card");
  const [candownload, setcandownload] = useState(true);
  const [paymentStatus, setPaymentStatus] = React.useState<PaymentStatus['status']>('IDLE');
  const [selected, setselected] = useState<ModelD>();
  const [model, setModel] = useState<ModelD[]>([]);
    const [paypalerror, setpaypalerror] = useState('');
  const { orderId } = useParams();
  const goToAboutPage = () => {
    navigate('/buy');
  };

  const initialOptions = {
    clientId: process.env.REACT_APP_PAYPALCLIENT? process.env.REACT_APP_PAYPALCLIENT: "",
    currency: "EUR",
  };
  function convertCentsToEuros(cents: number): string {
    const euros = cents / 100;
    return new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2
    }).format(euros);
}



  useEffect(() => {
    const fetchModels = async () => {
      try {
        const uid = auth.currentUser?.uid;
        if (uid) {

               const ordersRef = collection(firestore, "Orders");
                         // Query per cercare ordini con Stato "Pending"
              const q = query(
                ordersRef, 
                where("Buyer", "==", uid), 
                where("Stato", "!=", 0),
                orderBy("createdAt", "desc")
              );
                 
                         // Recupera i documenti corrispondenti
             const querySnapshot = await getDocs(q); // Ottieni tutti i documenti nella collezione "ordini"
  
          const models: ModelD[] = [];
  
          for (const doc of querySnapshot.docs) {
            if(doc.exists()) {
            if ( doc.data()?.Stato >= 1) {
              const createdAt = querySnapshot.docs[0].data()?.createdAt.toDate();
              const day = createdAt.getDate();
  const month = createdAt.getMonth() + 1; // I mesi in JavaScript partono da 0, quindi aggiungi 1
  const year = createdAt.getFullYear();
  const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
              const modelData: ModelD = {
                id: doc.id,
                Titolo: doc.data()?.Titolo,
                descrizione: doc.data()?.Descrizione,
                categoria: doc.data()?.Categoria,
                Prezzo: doc.data()?.Prezzo,
                createdat: formattedDate,
                notes: doc.data()?.notes,
                realstate: doc.data()?.Stato,
                state: doc.data()?.Stato == 1 ? "Requested" :  
                doc.data()?.Stato == 2 ? "Check-out" : 
                doc.data()?.Stato == 3 ? "Working on" :
                doc.data()?.Stato == 4 ? "Delivered" : 
                doc.data()?.Stato == 5 ? "Completed" : "?",
                immagini: [], // Inizializza come array di stringhe
              };
  
              const storageRef = ref(storage, `Orders/${uid}/${doc.id}`);
              const imagesList = await listAll(storageRef); // Ottieni tutti i file nella cartella
  
              const imageUrls = await Promise.all(
                imagesList.items.map(async (itemRef) => {
                  const url = await getDownloadURL(itemRef); // Recupera l'URL di ciascuna immagine
                  return url;
                })
              );
  
              if (imageUrls.length > 0) {
                modelData.immagini = imageUrls; // Assegna gli URL delle immagini
              }
  
              models.push(modelData); // Aggiungi il modello alla lista
            }
          }else{
return;
          }
          }
  
          setModel(models); // Imposta lo stato con tutti i modelli recuperati
          return;
        }
      } catch (error) {
        console.error("Errore nel recupero dei documenti:", error);
      } finally {
        setLoading(true); // Imposta il caricamento a false
      }
    };
  
    fetchModels();
  }, []);


  useEffect(() => {
    // Verifica che Firebase Authentication sia attivo e monitori lo stato di autenticazione
    const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (model.length > 0) {
      setselected(model[0])
    }
    });

    return () => unsubscribe(); // Cleanup per evitare memory leak
  }, [model]);

  const downloadFile = (url: string) => {

    const link = document.createElement('a'); // Crea un elemento <a>
    link.href = url; // Imposta l'URL del file
    link.download = ''; // Imposta l'attributo download
    document.body.appendChild(link); // Aggiungi il link al DOM
    link.click(); // Simula un clic sul link per avviare il download
    document.body.removeChild(link); // Rimuovi il link dal DOM
  };
const handlePayment = async (event: React.FormEvent) => {
    event.preventDefault();
  
    setLoading(true);

    const apiClient = process.env.REACT_APP_APICLIENT || "invalid";
    const token = await auth.currentUser?.getIdToken();

    const response = await fetch(apiClient, {
      
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ modello: selected?.id, action: "stripeintent", token }),
    });

    const { id } = await response.json();

    if (stripe) {
        stripe.redirectToCheckout({ sessionId: id });
    }
    setLoading(false);
    
  };
  const handledownload = async () => {
    setcandownload(false)
    try {
     
      const user = auth.currentUser;
      if (!user) {
        console.error("Nessun utente autenticato. Assicurati di aver effettuato il login.");
        return null; // Nessun token disponibile
    }
      const token = await user.getIdToken();

      
      const apiClient = process.env.REACT_APP_APICLIENT || "invalid";
      const response = await axios.post(apiClient, {
        action: 'getfile',
        modello: selected?.id,
        token: token,
      });
    
      if (response.data.success) {
        const fileUrl = response.data.fileUrl; // Ottieni l'URL del file dal server
        // Opzionale: reindirizza l'utente all'URL del file per il download
     
       downloadFile(fileUrl);
      } 
    
    } catch (error) {

     
    } 
    setcandownload(true);
  }
  const handledelete = async () => {

         const user = auth.currentUser;
            if (!user) {
        
              throw new Error("User not authenticated");
            }
            const token = await user.getIdToken();
            const apiClient = process.env.REACT_APP_APICLIENT2|| "invalid";
       await axios.post(apiClient, {
        action: "deleterequest",
        id: selected?.id,
        token: token, 
        
      });
       window.location.reload()
    };


    if ( !loading  ) {
      return <div className='h-screen w-screen bg-home2 flex justify-center items-center'>
      <div className="loader2"></div>
      </div>
    }else if (model.length == 0) {
      return <div className='h-screen w-screen bg-home2 flex sm:justify-center items-center text-[#cecece]'>
        <div className='flex w-[100%] px-2 sm:px-20 mb-16 flex-row sm:justify-start'>
          <div className=''>
   <p className=' sm:text-[2.5rem] text-[1.7rem] text-center sm:text-start sm:leading-[5rem] leading-[3rem] font-semibold  text-gradient    font-inter  '>
Sorry
 </p>
 <p className='sm:text-[3rem] text-[2.2rem] text-center sm:text-start  sm:leading-[3rem] leading-[2rem] font-semibold   text-gradient  font-inter tracking-[0.1rem]    '>
 We did not find 
 </p>
 <p className='sm:text-[3.4rem] text-[2.2rem] text-center sm:text-start sm:leading-[4.3rem] leading-[3rem] font-semibold   text-gradient  font-inter tracking-[0.1rem]    '>
 any orders...
 </p>
<p className='text-[0.5rem] pr-2 max-w-full text-center sm:text-start  sm:max-w-[29rem] pb-4'>We have not found any order in our system. However, you still have the option to create a new one if needed. Alternatively, you can return to the home screen to explore other features, browse available options, or navigate through different sections to find what you are looking for. If you need further assistance, feel free to check the help section or contact support for guidance.</p>

<div className='flex flex-row justify-center sm:justify-start'>
<button  onClick={() => navigate("/")}  className='bg-[#A47040] rounded-[6px] text-[0.7rem] px-10 mr-2 text-center text-white  py-[0.5rem]' >Go home</button>
 <button onClick={() => navigate("/order")} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white px-10 py-[0.5rem] border-[1px] border-[#A7A7A7]'>Go back</button>
 </div>
 </div>
 
<div>

<FontAwesomeIcon icon={faSearch} className="xl:flex hidden ml-[5rem] w-[17rem] h-[17rem] text-[#ffffff]" />


</div>

 </div>
      </div>
    }
  return (
    <div className='flex flex-col h-screen  overflow-auto bg-orders3 scrollbar-custom2'  >
 
    {/* Scritta iniziale */}
    <div className='flex flex-1 '>
   
    <div  className={`h-[100%] flex-col mr-3 shadow-custom2  ${isMobile ? `${menu ? 'flex w-[100%] ' : 'hidden' }` : 'max-w-[15rem]' }   px-2 text-white cardbg3`}>
      
      <div className='flex flex-row  min-w-[13rem] '>
        <button onClick={() => navigate("/order")}className='flex-1 flex items-center  p-2 text-[0.9rem] z-50 text-white'>
        <FontAwesomeIcon icon={faArrowLeft} className="mr-1 text-[#ffffff]" />
        </button>
    <p className='text-white p-2  font-light font-imperial  '>Giangibuilds - Commissions</p>
    </div>
  {/* DIVISORE*/}
<div className='flex   px-2 items-center '>
<div className='flex flex-1 bg-[#757474c7] h-[1px] '>
  </div>
</div>

<p className='text-white py-2 pt-3 text-[0.9rem]   '>My orders</p>
{model.map((modello, index) => (
<div>
  <div className='flex flex-row'>
  {
      selected?.id == modello.id?
      <div className='flex bg-[#a1be73] mr-1 w-1 my-4'></div>
      :
      <div className='flex bg-[#fff0] mr-1 w-1 my-4'></div>
    }
          <button onClick={() => setselected(modello)} key={index} style={{borderColor:   selected?.id == modello.id? "#b1b1b19f" : "#201f1f9f"}}   className='cardbg2 border-b-2    mt-1 flex w-[100%]   p-2   min-w-[3rem]'  >
          
            <img
              src={modello.immagini[0]}
              className=' h-[3.5rem] rounded-[4px] w-[3.5rem] object-cover'
            />
            <div className=' flex w-[100%] h-[100%] items-start flex-col'>
      <div className='flex w-[100%] flex-row items-center space-x-2'> 
      <p className='flex flex-1 text-white pl-2 text-[0.55rem] '>{modello.Titolo.length > 2 ? modello.Titolo.substring(0, 12) + ".." : modello.Titolo}</p>
      <p style={{color: modello.realstate >= 4 ? "#3ad047" : "#e09737"}} className='  text-[0.6rem]'>{modello.state}</p>
        </div>
        <p className='text-[#A39C96] px-2 pt-1 max-w-[10rem] text-start text-[0.3rem]'>{modello.descrizione.length > 250 ? modello.descrizione.substring(0, 150) + "..." : modello.descrizione}</p>
        <div className='w-[100%] h-[100%] flex items-end align-bottom justify-end'>
        <p className='text-white text-[0.5rem]  '>{modello.createdat}</p>
          </div>
          </div>
     
</button>
</div>

</div>
))}
    </div>
    
<div className={`flex-row flex-1  ${isMobile ? `${menu ? 'hidden' : 'flex' }` : 'flex' }  items-start`}>
   <div className='flex flex-col h-screen w-[100%] xl:w-[55%] pl-3 '>
   <p className='text-white   pt-3 text-[2rem]'>{selected?.Titolo}</p>
   <div className="xl:max-w-[45rem]  w-[100%]">
   <p className='text-[#dddddd]  pb-[0.8rem] pt-0 overflow-hidden text-[0.5rem] break-words'>{selected?.descrizione.substring(0, 300) + "..." }</p>
</div>
<div className={` flex flex-1  pb-2 ${isMobile ? 'max-h-[10rem]'  : 'min-h-[6rem]' }  flex-row space-x-2 overflow-x-scroll mb-4 scrollbar-custom2`}>
   {selected?.immagini.map((file, index) => (

 <img
  key={index}
   src={file}
   className=" shadow-button-custom2   rounded-[5px] h-[100%] object-cover"
 />

     ))}
     </div>

{
  selected?.realstate == 1 || selected?.realstate == 3?

<div className='flex border-l-[3px] mb-4   pl-5 py-2  md:max-w-[45vw] flex-col space-y-3  text-white text-[0.6rem]'>
<div className="flex text-[0.7rem] flex-row ">

<p className='flex flex-1'>Title:</p>

{selected?.Titolo}

</div>

<div className="flex text-[0.7rem] flex-row ">

<p className='flex flex-1'>Date of request:</p>


{selected?.createdat}
</div>
<div className="flex text-[0.7rem] flex-row ">

<p className='flex flex-1'>Category:</p>


{selected?.categoria}

</div>
<div className="flex  text-[0.7rem] flex-row ">

<p className='flex flex-1'>State:</p>


{selected?.state}

</div>

<p className='text-white  leading-[0.4rem] pt-3 text-[1rem]'>No longer interested?</p>
<p className='text-[#d3d3d3]   text-[0.5rem]'>You can choose your preferred payment method at checkout, either by credit/debit card or PayPal. If you change your mind, you also have the option to cancel your order at any time by clicking the this button.</p>

<div className=''>
<button onClick={() => handledelete()} className='bg-[#474747] rounded-[5px] shadow-button-custom mt-2 px-3 flex justify-center text-[0.7rem] py-[0.30rem]  text-[#f57777] items-center '> Delete my order</button>
</div>
     </div>
  :

 (
  selected?.realstate == 4 || selected?.realstate == 5 ? 
<div className='flex flex-1 flex-col items-center'>
<p className='text-[2.5rem]   pb-0  font-inter     text-[#fcfcfc]'>
Download it!
</p>
<p className='text-[0.4rem]   pb-0  font-inter     text-[#fcfcfc]'>
Your project is now complete and ready for download! Click the button below to securely download your file. Please review the delivered content carefully. If there are any major issues or errors, let us know, and we will address them. Thank you for choosing our service! 
</p>
<div className='flex flex-row'>
{
  candownload ? 
<button onClick={handledownload}  className="h-[1.8rem] px-12 mx-2 mt-4 bg-[#219f23] text-white rounded-[5px] text-[0.7rem]" >
      Download now
    </button>
      : 
      <button 
        className="flex items-center h-[1.8rem] px-12 mt-4 bg-[#219f23] text-white rounded-[5px] text-[0.7rem]"
      >
        <p className="pr-1">Loading...</p>
        <div className="loader3"></div>
      </button>
  }
  
<button onClick={() => {window.location.href = "mailto:support@giangibuilds.com?subject=%5BInsert%20object%20of%20discussion%5D&body=%5BTell%20us%20what%20you%20need!%5D" }}  className="h-[1.8rem] px-12 mx-2 mt-4 bg-[#e2992c] text-white rounded-[5px] text-[0.7rem]" >
      Contact us
    </button>
    </div>
</div>
  :
  <div className='flex flex-col'>

<div className='flex flex-row'>

  <div className='flex flex-col w-[18rem] p-2  '>
  <p className='text-[1rem] leading-[1.5rem]  font-semibold pb-0  font-inter     text-[#fcfcfc]'>
Payment method
</p>
<div className='flex flex-col min-w-[15rem] flex-1'>
<motion.div
    whileHover={{ scale: 1.03 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
  >
<button  onClick={() => setselectedcard("Card")} className="w-[100%]   flex flex-row cardbg p-1 justify-center items-center rounded-[5px]">
  <div className='flex flex-1 space-x-1'>
  <img className='w-[2.3rem] bg-white rounded-[3px]' src={visa}></img>

  <img className='w-[2.3rem]  bg-white rounded-[3px]' src={master}></img>

  <img className='w-[2.3rem]  bg-white rounded-[3px]' src={discover}></img>
  <img className='w-[2.3rem]  bg-white rounded-[3px]' src={unionnp}></img>
  </div>
<p style={{color: selectedcard === "Card"? "#ffffff" : "#a3a3a3"}} className='text-[0.7rem] pr-2'> Credit card</p>
</button>
</motion.div>
<motion.div
    whileHover={{ scale: 1.03 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
  >
<button onClick={() => setselectedcard("Paypal")} className="w-[100%]  mt-2 flex flex-row cardbg p-1 justify-center items-center rounded-[5px]">
  <div className='flex flex-1 space-x-1'>
  <img className='w-[2.3rem] bg-white rounded-[3px]' src={paypal}></img>

  </div>
<p  style={{color: selectedcard === "Paypal"? "#ffffff" : "#a3a3a3"}} className=' text-[0.7rem] pr-2'>Paypal</p>
</button>
</motion.div>
<button className="w-[100%]  mt-2 flex flex-row cardbg p-1 justify-center items-center rounded-[5px]">
  <div className='flex flex-1 space-x-1'>
  <div className='w-[2.3rem] border-[1px] h-[1.5rem] bg-[#4d4d4d] rounded-[3px] text-[0.9rem]'>
  <div className='w-[100%] h-[1px] bg-[#ffffff] mt-2'>

  </div>
  </div>

  </div>
<p className='text-[#a3a3a3] text-[0.7rem] pr-2'> Promotional code</p>
</button>


</div>
</div>

<div className='flex flex-col pl-4 justify-center text-white '>
<p className='text-[1.0rem]'> Ready in </p>
  {
    selected?.notes ?
    <p className='text-[1.6rem] text-[#ce9d53] leading-[2.8rem]'>{selected?.notes}</p>
    :
<p className='text-[2rem] text-green-300 leading-[2.8rem]'>Error</p>
  }
  <p className='text-[1.0rem]'> We ask </p>
  {
    selected?.Prezzo ?
    <p className='text-[2rem] text-green-300 leading-[2.8rem]'>{convertCentsToEuros(selected?.Prezzo)}</p>
    :
<p className='text-[2rem] text-green-300 leading-[2.8rem]'>Error</p>
  }
  
  </div>

</div>
<p className='text-[0.45rem] mt-2  px-2    md:text-left  text-center font-inter     text-[#A39C96]'>You can choose your preferred payment method at checkout. We offer the flexibility to pay securely using either a credit or debit card or through PayPal. Simply select the option that works best for you and proceed with your payment.</p>

{
  selectedcard==="Card" ?

    <div className='flex w-[100%]  justify-center '>
        <motion.div
    whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
    style={{margin: 5}}
  >
<button onClick={handlePayment} className='bg-[#467427] rounded-[6px] w-[12rem]  text-[0.7rem] text-white  mb-1   py-[0.25rem]'>Go to check-out <FontAwesomeIcon className='pl-1' icon={faLock} /></button>
</motion.div>
<motion.div
    whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
    style={{margin: 5}}
  >
<button onClick={() => handledelete()} className='bg-[#974944] rounded-[6px] w-[12rem]  text-[0.7rem] text-white  mb-1   py-[0.25rem]'> Delete my order</button>
</motion.div>
</div>

  :

  <div className='flex w-[100%] justify-center items-center'>
      <motion.div
  whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
  transition={{ type: "spring", stiffness: 300, damping: 50 }}
  style={{margin: 5}}
>
<PayPalScriptProvider  options={initialOptions}>
  <PayPalButtons
    fundingSource='paypal'
    className='w-[12rem]'
    style={{ layout: "vertical" }}
    createOrder={async () => {
   
    const user = auth.currentUser
    if (!user) {
      return null
    }
    const token = await user.getIdToken()
    const apiClient = process.env.REACT_APP_APICLIENT;

    try{
      
    const order = await axios.post(apiClient? apiClient: "invalid", {
      action: 'paypalintent',
        modello: selected?.id,
        token: token, 
      });
      setpaypalerror("");
      return order.data.orderId;
    }catch(err: any){
      if(err.response.status=="429"){
     if (err.response.data == "Max 1"){
      setpaypalerror("Cannot make two requests in 3 seconds")
     }else{
      setpaypalerror("Too many requests, try again later")
     }
  }else{
    setpaypalerror(err.response.data.message)
  }
}
}}

    onApprove={(data, actions) => {
      setPaymentStatus('PENDING');
      if (actions && actions.order) {
        return actions.order.capture().then((details) => {
          setpaypalerror("")
          setPaymentStatus('COMPLETED');
          
        });
      } else {
        return Promise.reject(undefined);
      }
    }}
    onError={(err: any) => {
      if(paypalerror===""){
        setPaymentStatus('ERROR');
      }
    }}
  
  />
</PayPalScriptProvider>
</motion.div>
<motion.div
    whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
    style={{margin: 5}}
  >
<button onClick={() => handledelete()} className='bg-[#974944] rounded-[6px] w-[12rem]  text-[0.7rem] text-white  mb-2   py-[0.25rem]'> Delete my order</button>
</motion.div>
</div>

}







</div>
 )




}
</div>

<div className='xl:flex hidden flex-col  w-[45%]  p-2'>
<div className='flex flex-col pt-[5vh]'>
<div className='flex flex-row  '>
<div className='flex flex-col  items-center px-6'>

{selected?.realstate?
  <div className='flex flex-col h-[100%] w-[100%] justify-center items-center'>
<div style={{backgroundColor: selected?.realstate > 1 ? "#3c7dd1" : selected?.realstate == 1 ? "#6cff27c7" :  "#ffffff" }} className=' w-[2vw] min-h-[2vw]  rounded-full'/>
<div style={{backgroundColor: selected?.realstate > 1 ? "#3c7dd1" : selected?.realstate == 1 ? "#6cff27c7" :  "#ffffff" }} className='flex  w-[1px]  h-[100%] '/>
</div>
:
<div></div>
}


</div>

  <div className='flex flex-col  pr-2'>
  {selected?.realstate?
<p style={{color: selected?.realstate > 1 ? "#3c7dd1" : selected?.realstate == 1 ? "#6cff27c7" :  "#ffffff" }} className=' pl-2 leading-[1.4rem]  text-[2vw]'>Requested</p>
:
<div></div>
}
<p className='text-[#c9c9c9] px-2 pt-2 break-words  text-[1vh]' >We have received the customer's request and are currently evaluating whether to accept the order. At this stage, we carefully assess the feasibility of the project, review any necessary requirements, and determine if we can proceed with the request. No work has started yet, and we may reach out to the customer for additional details or clarifications before making a final decision. The request is under consideration, and once accepted, it will move to the next phase.</p>
<div className='pl-2 '>
<button onClick={() => navigate("/terms")} className='bg-[#525252] rounded-[5px] mt-2 px-3 flex justify-center text-[0.5rem] py-[0.30rem]  text-[#ebebeb] items-center '> <FontAwesomeIcon icon={faCircleInfo} className="mr-1 text-[#949090]" />Our terms</button>
</div>
<div className='flex py-2  px-2 items-center '>
<div className='flex flex-1 bg-[#ffffffc7] h-[1px] '>
  </div>
  </div>
</div>
</div>

<div className='flex flex-row  '>
<div className='flex flex-col   items-center px-6'>
{selected?.realstate?
  <div className='flex flex-col h-[100%] w-[100%] justify-center items-center'>
<div style={{backgroundColor: selected?.realstate > 2 ? "#3c7dd1" : selected?.realstate == 2 ? "#44a517" :  "#ffffff" }} className=' w-[2vw] min-h-[2vw]   rounded-full'/>
<div style={{backgroundColor: selected?.realstate > 2 ? "#3c7dd1" : selected?.realstate == 2 ? "#44a517" :  "#ffffff" }} className='flex  w-[1px]  h-[100%] '/>
</div>
:
<div></div>
}
</div>
  <div className='flex flex-col pr-2'>
  {selected?.realstate?
<p style={{color: selected?.realstate > 2 ? "#3c7dd1" : selected?.realstate == 2 ? "#44a517" :  "#ffffff" }} className=' pl-2 leading-[1.4rem]  text-[2vw] '>Check-out</p>
:
<div></div>
}
<p className='text-[#c9c9c9] px-2 pt-2    break-words  text-[1vh]' >We have reviewed and accepted the order. Now, we are waiting for the customer to confirm their willingness to proceed by completing the payment. If the customer decides to move forward, they must finalize the payment process before we can begin working on the project. This stage also includes reviewing any terms and conditions, setting expectations, and ensuring that all necessary agreements are in place. Once the payment is received, the project will transition to the production phase.</p>
<div className='pl-2 '>
<button onClick={() => navigate("/terms")}   className='bg-[#525252] rounded-[5px] mt-2 px-3 flex justify-center text-[0.5rem] py-[0.30rem]  text-[#ebebeb] items-center '> <FontAwesomeIcon icon={faCircleInfo} className="mr-1 text-[#949090]" />Our terms</button>
</div>
<div className='flex py-2  px-2 items-center'>
<div className='flex flex-1 bg-[#ffffffc7] h-[1px]'>
  </div>
  </div>
</div>
</div>

<div className='flex flex-row'>
<div className='flex flex-col   items-center px-6'>

{selected?.realstate?
  <div className='flex flex-col h-[100%] w-[100%] justify-center items-center'>
<div style={{backgroundColor: selected?.realstate > 3 ? "#3c7dd1" : selected?.realstate == 3 ? "#44a517" :  "#ffffff" }} className=' w-[2vw] min-h-[2vw]  rounded-full'/>
<div style={{backgroundColor: selected?.realstate > 3 ? "#3c7dd1" : selected?.realstate == 3 ? "#44a517" :  "#ffffff" }} className='flex  w-[1px]  h-[100%] '/>
</div>
:
<div></div>
}

</div>

  <div className='flex flex-col    pr-2'>
  {selected?.realstate?
<p style={{color: selected?.realstate > 3 ? "#3c7dd1" : selected?.realstate == 3 ? "#44a517" :  "#ffffff" }} className=' pl-2 leading-[1.4rem]  text-[2vw]'>Working on</p>
:
<div></div>
}
<p className='text-[#c9c9c9] px-2 pt-2    break-words  text-[1vh]' >The customer has successfully completed the payment, and our team is actively working on the project. We have started the development or production process based on the agreed specifications. During this phase, our focus is on delivering a high-quality product that meets the customer’s expectations. If necessary, we may provide updates, request feedback, or make adjustments to ensure the final outcome aligns with the initial request. The project is in progress, and we are committed to completing it efficiently and professionally.</p>
<div className='pl-2 '>
<button onClick={() => navigate("/terms")}  className='bg-[#525252] rounded-[5px] mt-2 px-3 flex justify-center text-[0.5rem] py-[0.30rem]  text-[#ebebeb] items-center '> <FontAwesomeIcon icon={faCircleInfo} className="mr-1 text-[#949090]" />Our terms</button>
</div>
<div className='flex py-2  px-2 items-center '>
<div className='flex flex-1 bg-[#ffffffc7] h-[1px] '>
  </div>
  </div>
</div>
</div>

<div className='flex flex-row  '>
<div className='flex flex-col  items-center px-6'>

{selected?.realstate?
  <div className='flex flex-col h-[100%] w-[100%] justify-center items-center'>
<div style={{backgroundColor: selected?.realstate >= 4 ?  "#44a517" :  "#ffffff" }} className=' w-[2vw] min-h-[2vw] bg-[#3c7dd1]  rounded-full'/>
<div  className='flex  w-[1px]  h-[100%] '/>
</div>
:
<div></div>
}

</div>

  <div className='flex flex-col pr-2'>
  {selected?.realstate?
<p style={{color: selected?.realstate >= 4 ?  "#44a517" :  "#ffffff" }} className=' pl-2 leading-[1.4rem]  text-[2vw]'>Delivered</p>
:
<div></div>
}
<p className='text-[#c9c9c9] px-2 pt-2    break-words  text-[1vh]' >The project has been successfully completed and the final product has been delivered to the customer. All requested deliverables have been provided, and the customer now has the opportunity to review and test the product. If needed, minor revisions or additional support may be offered to ensure customer satisfaction. At this stage, the project is considered finalized unless further modifications are requested within the agreed terms.</p>
<div className='pl-2 '>
<button onClick={() => navigate("/terms")}  className='bg-[#525252] rounded-[5px] mt-2 px-3 flex justify-center text-[0.5rem] py-[0.30rem]  text-[#ebebeb] items-center '> <FontAwesomeIcon icon={faCircleInfo} className="mr-1 text-[#949090]" />Our terms</button>
</div>

</div>
</div>
</div>



</div>
</div>
    </div>
     
    </div>
  );
}

export default Myorders;


