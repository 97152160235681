import React, {useEffect, useState} from 'react';
import '../styles.css';
import {Firestore, collection, doc, getDocs, getFirestore, onSnapshot, query, where, getDoc} from 'firebase/firestore'
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { motion } from "framer-motion";
import '../App.css';
import axios, { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
import logo from "../images/Frame 6.png"
import { storage } from "../FirebaseConfig";
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { auth, firestore} from '../FirebaseConfig';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar  } from '@fortawesome/free-solid-svg-icons';
import blender from '../images/blender.jpg'; // Importa l'immagine
import santa from '../images/Santa.png'; // Importa l'immagine
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from '../objects/Header';

interface ModelD {
  id: string,
  descrizione: string,
  categoria: string,
  createdat: string,
  Titolo: string,
  immagini: string[];
}

const Checkoutorder: React.FC = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingbutton, setLoadingbutton] = useState(false);
  const [logged, setlogged] = useState(false);
  const [sent, setsent] = useState(false);
  const [immaginenumber, setimmaginenumber] = useState(0);
  const [model, setModel] = useState<ModelD>(
    {
      id: "",
      descrizione: "",
      categoria: "",
      createdat:"",
      Titolo: "",
       immagini: []
    }
  );

  const goToAboutPage = () => {
    navigate('/buy');
  };

  useEffect(() => {
    // Verifica che Firebase Authentication sia attivo e monitori lo stato di autenticazione
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('Utente loggato:');
        setlogged(true)
   
      } else {
        console.log('Nessun utente loggato');
  
      }
    });

    return () => unsubscribe(); // Cleanup per evitare memory leak
  }, []);



  useEffect(() => {
    const fetchModel = async () => {
      try {
        const uid = auth.currentUser?.uid;
        if (uid) {
       const ordersRef = collection(firestore, "Orders");
     
             // Query per cercare ordini con Stato "Pending"
             const q = query(
              ordersRef, 
              where("Buyer", "==", uid), 
              where("Stato", "==", 0)
          );
     
             // Recupera i documenti corrispondenti
             const querySnapshot = await getDocs(q);

console.log(querySnapshot)
          if ( !querySnapshot.empty ) {
            const createdAt = querySnapshot.docs[0].data()?.createdAt.toDate();
            const day = createdAt.getDate();
const month = createdAt.getMonth() + 1; // I mesi in JavaScript partono da 0, quindi aggiungi 1
const year = createdAt.getFullYear();

// Formatta la data come stringa (esempio: "09/02/2025")
const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
            const modelData: ModelD = {
              id:  querySnapshot.docs[0].id,
              Titolo: querySnapshot.docs[0].data()?.Titolo,
              descrizione:  querySnapshot.docs[0].data()?.Descrizione,
              categoria: querySnapshot.docs[0].data()?.Categoria,
              createdat: formattedDate,
              immagini: [] // Inizializza come array di stringhe
            };
  
              const storageRef = ref(storage, `Orders/${uid}/${querySnapshot.docs[0].id}`);
              const imagesList = await listAll(storageRef); // Ottieni tutti i file nella cartella
    
              const imageUrls = await Promise.all(
                imagesList.items.map(async (itemRef) => {
                  const url = await getDownloadURL(itemRef); // Recupera l'URL di ciascuna immagine
                  return url;
                })
              );
    
              if (imageUrls.length > 0) {
                modelData.immagini = imageUrls; // Assegna gli URL delle immagini
              }
              
              setModel(modelData);
              return true // Imposta i dati del modello
            }
          }
      
      } catch (error) {
        console.error("Errore nel recupero del documento:", error);
      } finally {
        setLoading(true)
      }
    };

    const intervalId = setInterval(async () => {
      const found = await fetchModel();
      if (found) {
        clearInterval(intervalId); // Interrompe il polling se il modello è trovato
      }
     
    }, 2000);
  
    // Cleanup dell'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId); // Chiama la funzione di fetch
}, []);

const handleSubmit = async () => {
if (!loadingbutton) {
setLoadingbutton(true)
     const user = auth.currentUser;
        if (!user) {
          setLoadingbutton(false)
          throw new Error("User not authenticated");
        }
        const token = await user.getIdToken();
        const apiClient = process.env.REACT_APP_APICLIENT2|| "invalid";
  const response = await axios.post(apiClient, {
    action: "sendrequest",
    id: model.id,
    token: token, 
  
  });
  if(response.data.success) {
setsent(true);
  }
  setLoadingbutton(false)
}
};

const handleDelete = async () => {

  const user = auth.currentUser;
     if (!user) {
       throw new Error("User not authenticated");
     }
     const token = await user.getIdToken();
     const apiClient = process.env.REACT_APP_APICLIENT2|| "invalid";
const response = await axios.post(apiClient, {
 action: "deleterequest",
 id: model.id,
 token: token, 
});
navigate(-1)
};





const logout =  async ()  => {

signOut(auth);
}


  if (logged == false) {
    return (
      <div>request failed: not logged</div>
    )
    }
    if (!loading) {
      return <div className='h-screen w-screen bg-home2 flex justify-center items-center'>
      <div className="loader2"></div>
      </div>
    }
    if (sent) {
      return <div className='h-screen w-screen bg-home2 flex flex-col justify-center items-center'>
            <Elements stripe={loadStripe(process.env.REACT_APP_STRIPECLIENT? process.env.REACT_APP_STRIPECLIENT: "")}>
  <Header/>
  </Elements>
     <div className='flex flex-col mb-14 flex-1  items-center     justify-center  '>
      
     
       
     
      <p className=' text-[2.4rem] leading-[2.3rem]  font-semibold  text-gradient    font-inter  '>
     Thank you
      </p>
    
           <p className='text-[2.9rem] leading-[3.7rem] font-semibold   text-gradient  font-inter tracking-[0.1rem]    '>
     for choosing us
      </p>
    
     
      <p className='text-[0.6rem]   font-inter  max-w-[30rem] st  text-center     text-[#A39C96]'>
      Your request has been successfully submitted! Our team will review it as soon as possible and get back to you shortly. Thank you for choosing us!
      </p>
     
      <div className='flex    pt-5  '>
  
     
            <button  onClick={() => navigate("/order")}   className='bg-[#A47040] rounded-[6px] text-[0.7rem] px-8 mr-2 text-center text-white  py-[0.5rem]' >Back to home</button>
     
      
  
                 <button onClick={() => navigate("/myorders")} className='bg-transparent  rounded-[6px] text-[0.7rem] text-white px-10 py-[0.5rem] border-[1px] border-[#A7A7A7]'>My orders</button>
      
      </div>
     
     
     
     
      
         </div>
      </div>
    }

  return (
    <div className='flex flex-col h-screen  overflow-auto bg-orders  scrollbar-custom '  >
    <Elements stripe={loadStripe(process.env.REACT_APP_STRIPECLIENT? process.env.REACT_APP_STRIPECLIENT: "")}>
  <Header/>
  </Elements>
  
    {/* Scritta iniziale */}
    <div className='flex flex-1 justify-center items-center pb-10'>
     <div className='flex flex-1 sm:flex-row flex-col  justify-center' >
   

    <div className=' flex-col items-start justify-start pt-6 p-3 hidden md:flex    '>
  <div>
  <p className=' text-[2.4rem]  leading-[2.4rem] font-semibold  pl-0   sm:pl-[4rem]  font-inter m-0 p-0 text-[#ffffff]'>
  Finish 
  </p>
 
  <p className='text-[2.4rem] leading-[3.7rem] font-semibold   font-inter    pl-0   sm:pl-[4rem] text-[#ffffff]'>
  Your order
  </p>
  <p className='text-[0.6rem] sm:pr-0  pr-10 pl-10  py-2  font-inter  max-w-[25rem] sm:text-left  text-center    sm:pl-[4rem] text-[#A39C96]'> You don’t have to pay at the moment, just send your request and we will see it in max 48 hours, send you an offer and you will be able to accept it or decline it. You can see you orders on “cronology orders” . Once you declined it, you will not be able to accept it unless you make another order.   </p>
 
<div className='text-[0.6rem] sm:pr-0  pr-10 pl-10   font-inter  max-w-[25rem] sm:text-left  text-center    sm:pl-[4rem] text-[#A39C96]'>
 Our best Reviews
</div>

{false? 
<div className='flex flex-row space-x-2 sm:pr-0  pr-10 pl-10  py-2  font-inter  max-w-[25rem] sm:text-left  text-center    sm:pl-[4rem]'>
   
   
   <motion.div
    whileHover={{ scale: 1.05 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 400, damping: 50 }}
  >
<div className='flex  flex-col border-[1px] max-w-[10rem] rounded-[5px] border-[#4A443F] p-2 '>
<div className='flex flex-row py-1 '>
<img src={blender} className='w-8 h-8 mr-2 rounded-full '></img>
<div className='flex flex-col'>
  <p className='text-white pt-[0.2rem] text-[0.85rem] leading-[1rem] font-inter'>REgelly150</p>
  <div className='flex flex-row space-x-[4px]'>
<FontAwesomeIcon icon={faStar} color='#E1B437' className='w-2'></FontAwesomeIcon>
<FontAwesomeIcon icon={faStar} color='#E1B437' className='w-2'></FontAwesomeIcon>
<FontAwesomeIcon icon={faStar} color='#E1B437' className='w-2'></FontAwesomeIcon>
<FontAwesomeIcon icon={faStar} color='#E1B437' className='w-2'></FontAwesomeIcon>
<FontAwesomeIcon icon={faStar} color='#E1B437' className='w-2'></FontAwesomeIcon>
</div>
</div>
</div>
<p className='text-[#A39C96] text-[0.4rem]'>These are very great stuffs! Keep your works! I really liked the quality and the price was very correct! Thanks for everything!</p>
</div>
</motion.div>
<motion.div
    whileHover={{ scale: 1.05 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 400, damping: 50 }}
  >
<div className='flex  flex-col border-[1px] max-w-[10rem] rounded-[5px] border-[#4A443F] p-2 '>
<div className='flex flex-row py-1 '>
<img src={blender} className='w-8 h-8 mr-2 rounded-full '></img>
<div className='flex flex-col'>
  <p className='text-white pt-[0.2rem] text-[0.85rem] leading-[1rem] font-inter'>REgelly150</p>
  <div className='flex flex-row space-x-[4px]'>
<FontAwesomeIcon icon={faStar} color='#E1B437' className='w-2'></FontAwesomeIcon>
<FontAwesomeIcon icon={faStar} color='#E1B437' className='w-2'></FontAwesomeIcon>
<FontAwesomeIcon icon={faStar} color='#E1B437' className='w-2'></FontAwesomeIcon>
<FontAwesomeIcon icon={faStar} color='#E1B437' className='w-2'></FontAwesomeIcon>
<FontAwesomeIcon icon={faStar} color='#E1B437' className='w-2'></FontAwesomeIcon>
</div>
</div>
</div>
<p className='text-[#A39C96] text-[0.4rem]'>These are very great stuffs! Keep your works! I really liked the quality and the price was very correct! Thanks for everything!</p>
</div>

</motion.div>


</div>
:
<div className='flex flex-row space-x-2 text-[#696765] text-[0.7rem]   py-2  font-inter  max-w-[25rem] sm:text-left  text-center    sm:pl-[4rem] justify-center'>Still no reviews yet</div>
}
  </div>
  <div className='flex flex-row space-x-1'>
  <button onClick={() => navigate("/createorder")} className=" text-[#4169eb] text-[0.6rem]   py-2  font-inter  max-w-[25rem] sm:text-left  text-center    sm:pl-[4rem]">Modify</button>
  <button onClick={() => handleDelete()} className=" text-[#b8362d] text-[0.6rem]    py-2  font-inter  max-w-[25rem] sm:text-left  text-center  pl-2   ">Delete order</button>
</div>
  </div>





<div className='flex  flex-col    items-center pr-0  sm:pr-16 '>
  <div className='flex  w-[100%]  justify-center sm:justify-start sm:items-start '>
  <p className='text-white text-[4rem]      sm:text-[1rem]  '>Your order</p>
  </div>

   <div className={`flex w-full items-center flex-col p-2  font-inter font-light ${isMobile ? 'sm:max-w-[18rem] sm:border-2 sm:border-collapse sm:border-[#6161617a]' : 'max-w-[18rem] border-2 border-collapse border-[#6161617a]'} rounded-[5px]  `}>
   <button   onClick={() => setimmaginenumber(immaginenumber === (model.immagini.length - 1) ? 0 : immaginenumber + 1)}>
   <img 

        src={model.immagini[immaginenumber]} // sostituisci con il tuo URL dell'immagine
        alt="example"
        className=" w-[15rem] h-[15rem] sm:w-[5rem] rounded-[5px] my-3 sm:h-[5rem] object-cover object-center "
      />
      </button>
        <p className='text-white text-[2rem] sm:text-[0.6rem]  '>{model.Titolo}</p>
        <p className='text-[#dadada] text-[0.4rem] sm:text-[0.3rem] mt-2 sm:w-[12rem] break-words whitespace-normal '>{model.descrizione.length > 250 ? model.descrizione.substring(0,250) + "..." : model.descrizione}</p>
        <div className='w-[100%] my-2 bg-[#5a5a5ac7] h-[1px] '></div>
     
     <div className='flex flex-col space-y-6 sm:space-y-3 w-[100%] text-white text-[1rem] sm:text-[0.6rem]'>
<div className="flex flex-row ">

<p className='flex flex-1'>Title:</p>


{model.Titolo}

</div>

<div className="flex flex-row ">

<p className='flex flex-1'>Date of request:</p>


{model.createdat}
</div>
<div className="flex flex-row ">

<p className='flex flex-1'>Category:</p>


{model.categoria}

</div>
<div className="flex flex-row ">

<p className='flex flex-1'>State:</p>


<p >Pending</p>

</div>


     </div>

     <div className='w-[100%] my-2 bg-[#5a5a5ac7] h-[1px] '></div>


 <motion.div
      whileHover={{ scale: 1.05 }} // Quando si passa sopra, l'elemento aumenta di dimensione
      transition={{ type: "spring", stiffness: 400, damping: 50 }}
    >
{loadingbutton? 
  <button  className='bg-[#A47040]  flex sm:  rounded-[6px] text-[0.5rem] px-6 text-center w-[10rem] items-center justify-center text-white  py-[0.4rem]' >
  <div className="loader3"></div></button>
:

       <button   onClick={() =>  handleSubmit()} className='bg-[#A47040] rounded-[6px] text-[1rem] sm:text-[0.5rem] px-6 mr-2 text-center text-white mt-[0.6rem]  py-[0.4rem]'  >Send a request now</button>
    
}
       </motion.div>



     </div>
     
  
    </div>
    {/* FRAME_2 */}
    
    </div>
    </div>



    
    </div>
  );
}

export default Checkoutorder;


