import React, {useEffect, useState} from 'react';
import '../styles.css';
import { motion } from "framer-motion";
import '../App.css';
import { Link } from 'react-router-dom';
import {  doc, setDoc, getDocs, collection, query, where } from "firebase/firestore";
import logo from "../images/Frame 6.png"
import { useNavigate } from 'react-router-dom';
import { auth, firestore} from '../FirebaseConfig';
import blender from '../images/blender.jpg'; // Importa l'immagine
import santa from '../images/Santa.png'; // Importa l'immagine
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from '../objects/Header';










const Order: React.FC = () => {
  const navigate = useNavigate();
 

   const checkPendingOrder = async () => {
        try {
          const uid = auth.currentUser?.uid; // Ottieni l'ID dell'utente attualmente autenticato
          if (!uid) {
           navigate("/login")
            return;
          }
  
            navigate("/createorder")
            
        } catch (error) {
          console.error("Errore durante la verifica degli ordini:", error);
        }
      };
  

const logout =  async ()  => {

signOut(auth);
}




  return (
    <div className='flex flex-col h-screen  overflow-auto bg-orders  scrollbar-custom '  >
      <Elements stripe={loadStripe(process.env.REACT_APP_STRIPECLIENT? process.env.REACT_APP_STRIPECLIENT: "")}>
  <Header/>
  </Elements>
  
    {/* Scritta iniziale */}
    <div className='flex flex-col mb-14 flex-1  items-center     justify-center  '>
 

  

 <p className=' sm:text-[3.2rem] text-[2rem] sm:leading-[3rem] leading-[2rem]  font-semibold  text-gradient    font-inter  '>
Just order
 </p>
 <motion.div
     initial={{ opacity: 0, y: 10 }} // Inizia invisibile e più in basso
     animate={{ opacity: 1, y: 0 }}   // Diventa visibile e si sposta verso l'alto
     transition={{ duration: 1 }}     // Imposta la durata dell'animazione
    >
      <p className='sm:text-[3.7rem] text-[2.2rem] sm:leading-[4.7rem] font-semibold   text-gradient  font-inter tracking-[0.1rem]    '>
what you need
 </p>
    </motion.div>

 <p className='sm:text-[0.7rem] text-[0.5rem]   font-inter  max-w-[40rem] st  text-center     text-[#A39C96]'>
 Looking for top-quality digital products delivered in no time? We offer fast and efficient commissions for Roblox files, Blender assets, and more! Our work is designed to meet your needs with precision and excellence, ensuring you get the best results in the shortest time possible.

Just place your order, and we’ll handle the rest! 🚀
 </p>

 <div className='flex    pt-5  '>
 <motion.div
      whileHover={{ scale: 1.05 }} // Quando si passa sopra, l'elemento aumenta di dimensione
      transition={{ type: "spring", stiffness: 400, damping: 50 }}
    >

       <button  onClick={() => checkPendingOrder()}  className='bg-[#A47040] rounded-[6px] text-[0.7rem] px-8 mr-2 text-center text-white  py-[0.5rem]' >Let's get started</button>
    </motion.div>
 
  <motion.div
      whileHover={{ scale: 1.05 }} // Quando si passa sopra, l'elemento aumenta di dimensione
      transition={{ type: "spring", stiffness: 400, damping: 50 }}
    >
            <button onClick={() => auth.currentUser?  navigate("/myorders") : navigate("/login") } className='bg-transparent  rounded-[6px] text-[0.7rem] text-white px-10 py-[0.5rem] border-[1px] border-[#A7A7A7]'>My orders</button>
    </motion.div>
  
 </div>




 
    </div>



    <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
       © 2025 Giangibuilds from Gianluigi Izzo
    </div>
    </div>
  );
}

export default Order;