// src/App.tsx
import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import Login from './screens/Login';
import BuyModels from './screens/BuyModels';
import Register from './screens/Register';
import Home from './screens/Home';
import More from './screens/More';
import Passwordforgot from './screens/Passwordforgot';
import Buy from './screens/Buy';
import Cronologia from './screens/cronology';
import Tos from './screens/Tos';
import { auth, firestore } from './FirebaseConfig';
import { doc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import VerifyEmail from "./screens/VerifyEmail";
import Order from "./screens/Orders";
import Createorder from "./screens/Createorder";
import Success from "./screens/Success";
import Myorders from './screens/Myorders';
import Checkoutorder from "./screens/ordercheckout";
import Cancel from "./screens/Cancel";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPECLIENT? process.env.REACT_APP_STRIPECLIENT: "");


const App: React.FC = () => {
  const [logged, setlogged] = useState(false);
  const [completed, setcompleted] = useState(false);
   const auth = getAuth();
  const user = auth.currentUser;
  const [serverStatus, setServerStatus] = useState(true); // Default: il sito è online

  useEffect(() => {

    const docRef = doc(firestore, "config", "serverstatus");
  
    onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setServerStatus(doc.data().status);
      }
    });
  }, []);


  useEffect(() => {
    
    // Verifica che Firebase Authentication sia attivo e monitori lo stato di autenticazione
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setcompleted(false)
      setlogged(false)
      if (user) {
        setlogged(true)
        setcompleted(true)
      } else {
        setcompleted(true)
        setlogged(false)
      }
    });

    return () => unsubscribe(); // Cleanup per evitare memory leak
  }, [user?.emailVerified]);

  if (completed == false) {
    return null
  }



    return  (
      
      <Router>
        {!serverStatus && (
      <div className='bg-red-600 text-white text-[1rem] flex text-center fixed bottom-0 w-full z-[100000] '>
        ⚠️ Our server is currently undergoing maintenance. Some features may be unavailable at this time. We apologize for the inconvenience and appreciate your patience. ⚠️
      </div>
    )}
        <Routes>
          {user ? (
            user.emailVerified ? (
              <>
                {/* Utente autenticato e verificato */}
                <Route path="/" element={  
                   <Elements stripe={stripePromise}>
                      <Home />
                    </Elements>} />
                <Route
                  path="/articles/:articleId"
                  element={
                    <Elements stripe={stripePromise}>
                      <More />
                    </Elements>
                  }
                />
                <Route path="/buy" element={<Buy />} />
                <Route path="/BuyModels/:Category/:Range/:Sort/:Page" element={<BuyModels />} />
                <Route path="/cronology" element={<Cronologia />} />
                <Route path="/terms" element={<Tos />} />
                <Route path="/order" element={<Order />} />
                <Route
                  path="/myorders"
                  element={
                    <Elements stripe={stripePromise}>
                      <Myorders />
                    </Elements>
                  }
                />
                <Route path="/createorder" element={<Createorder />} />
                <Route path="/success" element={<Success />} />
                <Route path="/Cancel" element={<Cancel />} />
                <Route path="/checkoutorder" element={<Checkoutorder />} />
                <Route path="/register" element={<Navigate to="/" />} />
                <Route path="/login" element={<Navigate to="/" />} />
                <Route path="/verify-email" element={<Navigate to="/" />} />
                <Route path="/passwordforgotten" element={<Navigate to="/" />} />
              </>
            ) : (
              <>
                {/* Utente autenticato ma email non verificata */}
                <Route path="/" element={<VerifyEmail />} />
              </>
            )
          ) : (
            <>
              {/* Utente non autenticato */}
              <Route path="/" element={<Home />} />
                <Route
                  path="/articles/:articleId"
                  element={
                    <Elements stripe={stripePromise}>
                      <More />
                    </Elements>
                  }
                />
                <Route path="/buy" element={<Buy />} />
                <Route path="/BuyModels/:Category/:Range/:Sort/:Page" element={<BuyModels />} />
                <Route path="/terms" element={<Tos />} />
                <Route path="/order" element={<Order />} />
                <Route path="/register" element={<Register/>} />
                <Route path="/login" element={<Login/>} />
                <Route path="/verify-email" element={<VerifyEmail/>} />
                <Route path="/passwordforgotten" element={<Passwordforgot/>} />


                <Route
                  path="/myorders"
                  element={<Navigate to="/login" />}
                />
                <Route path="/createorder" element={<Navigate to="/login" />} />
                <Route path="/checkoutorder" element={<Navigate to="/login" />} />
                <Route path="/cronology" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      </Router>
    );
};

export default App;
