// src/components/Product.tsx
import React from 'react';
import blender from '../images/blender.jpg';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

// Definiamo il tipo delle props per il componente Product
interface ProductProps {
  name: string
  description: string;
  image: string;
  Acquisti:number;
  prezzo: number;
  id: string;
  path: string;
}

const Product: React.FC<ProductProps> = ({name,  description, image, Acquisti ,prezzo, id, path}) => {
  const formatEuro = (value: number) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(value);
  };
  const navigate = useNavigate();
  const goToAboutPage = ( articleId: string) => {
    navigate(`/articles/${articleId}`);
  };

  return (
    <motion.div
    whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 400, damping: 50 }}
  >
    <button onClick={() => goToAboutPage(id)} className="flex flex-col w-[10rem] bg-[#141210]  border-[2px] border-[#2e2c29] py-2 mt-3  rounded-[5px] px-2">
      <div className='flex flex-col justify-start items-start'>
        <div className='flex h-[6rem]'>
<div className=' w-[100%]  rounded-[5px]  bg-slate-200'>
<img src={image} alt="Logo" className=' w-full h-full object-cover object-center rounded-[5px] " brightness-[90%] '/> {/* Inserisci l'immagine */}
</div>
</div>

<div className='text-white'>
{name.length > 17 ? name.substring(0,17) + "..." : name}
</div>
    
<div className='flex text-[#9c9a95] text-[0.5rem] text-left pb-2'>
{description.length > 63 ? description.substring(0,63) + "..." : description}
</div>

<div className='flex w-[100%] mt-1 flex-row '>
  {prezzo == 0 ?
  <div className='flex flex-1  text-[0.6rem] text-[#6fb446]'>
  Free
  </div>
  :
  <div className='flex flex-1  text-[0.6rem] text-[#6fb446]'>
    {formatEuro(prezzo)}
    </div>
  }

  <div className=' flex  text-[0.6rem] text-[#9c9a95]'>Purshases: {Acquisti}</div>
  
    </div>
</div>

    </button>
    </motion.div>
 
  );
};

export default Product;
