// src/components/Product.tsx
import React from 'react';
import  {useEffect, useState} from 'react';
import blender from '../images/blender.jpg';
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Firestore, Timestamp, collection, doc, getDoc, onSnapshot, arrayRemove , where, query, orderBy, getDocs, limit, arrayUnion, updateDoc, setDoc} from 'firebase/firestore'
import { faTrash, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import visa from '../images/Visa.png'; // Importa l'immagine
import master from '../images/mastercard.png'; // Importa l'immagine
import paypal from '../images/paypal.png'; // Importa l'immagine
import discover from '../images/disover.png'; // Importa l'immagine
import unionnp from '../images/unionp.png'; // Importa l'immagine
import '../styles.css';
import '../App.css';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useStripe, useElements} from '@stripe/react-stripe-js';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Link } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useLocation } from "react-router-dom";
import { auth, firestore } from '../FirebaseConfig';
import { storage } from "../FirebaseConfig";
import axios from 'axios';
import { onAuthStateChanged, signOut } from 'firebase/auth';

// Definiamo il tipo delle props per il componente Product
interface PaymentStatus {
  status: 'IDLE' | 'PENDING' | 'COMPLETED' | 'ERROR';
}
const initialOptions = {
  clientId: process.env.REACT_APP_PAYPALCLIENT? process.env.REACT_APP_PAYPALCLIENT: "",
  currency: "EUR",
};
const Header: React.FC = () => {
    const [cart, setcart] = useState(false);
      const stripe = useStripe();
      const location = useLocation();
      const [paymentStatus, setPaymentStatus] = React.useState<PaymentStatus['status']>('IDLE');
        const [paypalerror, setpaypalerror] = useState('');
    const [cartmodels, setcartmodels] = useState<modellicarrello[]>([]);
    const [soloid, setsoloid] = useState<string[]>([]);
    const [prezzototale, setprezzototale] = useState(0);
      const [selected, setselected] = useState("Card");
  const navigate = useNavigate();
      interface modellicarrello {
  
        id: string,
        Nome: string,
        prezzo: number,
        immagine: string,
      
        }
const logout =  async ()  => {

signOut(auth);
}
const handlePayment = async (event: React.FormEvent) => {
          event.preventDefault();
        
      
      
          const apiClient = process.env.REACT_APP_APICLIENT || "invalid";
          const token = await auth.currentUser?.getIdToken();
      if (!token) {
       return navigate("/login")
      }
          const response = await fetch(apiClient, {
              
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
              token: token,
              modelli: soloid,
              action: "stripeintent"
              }),
          });
      
          const { id } = await response.json();
      
          if (stripe) {
              stripe.redirectToCheckout({ sessionId: id });
          }
        
          
        };  
  useEffect(() => {
    const user = auth.currentUser?.uid
    
    if (!user) {
      console.error("Utente non autenticato o displayName mancante");
      return;
    }
  

    
  
      const userCheckoutDoc = doc(firestore, "Checkouts", user);

      const unsubscribe = onSnapshot(userCheckoutDoc, async (snapshot) => {
       if (!snapshot.exists()) {
       setcartmodels([]);
       return;
      }
                           
      const allModelIds = snapshot.data()?.Modelli || [];
      let prezzototale = 0
      const orders = await Promise.all(
        allModelIds.map(async (modelId: string) => {
          if (!modelId) return null; // Se l'ID non esiste, ignora

        const modelRef = doc(firestore, "Modelli", modelId);
    const modelSnap = await getDoc(modelRef);

    if (!modelSnap.exists()) return null; // Se il documento non esiste, ignora

    const modelData = modelSnap.data();
const imageRef = ref(storage, `Modelli/${modelId}/${modelId}.jpg`);
      const immagine = await getDownloadURL(imageRef);
prezzototale = prezzototale + modelData?.Prezzo
        return {
       id: modelId,
       Nome: modelData?.Nome,
       prezzo: modelData?.Prezzo,
       immagine: immagine,
        }

     }));

     setcartmodels(orders.filter((order): order is modellicarrello => order !== null));
     setsoloid(allModelIds); // Aggiorna lo stato con i modelli aggiornati
     setprezzototale(prezzototale)
    }, (error) => {
      console.error("Errore nell'ascoltare i modelli:", error);
    });
  
    // Cleanup: chiude la connessione quando il componente viene smontato
    return () => unsubscribe();
  }, []);

  const formatEuro = (value: number) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(value);
  };

  const rimuovimodello = async (value: number) => {

const modelloid = soloid[value]
console.log(modelloid)

const user = auth.currentUser?.uid;

  if (!user) {
    console.error("Utente non autenticato");
    return;
  }

  const checkoutRef = doc(firestore, "Checkouts", user); // Documento del checkout dell'utente

  try {
    const checkoutSnap = await getDoc(checkoutRef);

    if (checkoutSnap.exists()) {
    
      await updateDoc(checkoutRef, {
        Modelli: arrayRemove(modelloid), 
      });
    
      console.log(`Modello ${modelloid} rimosso dal carrello con successo!`);
    } else {
      console.log("Il documento non esiste, quindi non c'è nulla da rimuovere.");
    }
  }
     catch (error) {
    console.error("Errore durante l'aggiunta al carrello:", error);
  }

  };
  const rimuovituttimodelli = async () => {


    
    const user = auth.currentUser?.uid;
    
      if (!user) {
        console.error("Utente non autenticato");
        return;
      }
    
      const checkoutRef = doc(firestore, "Checkouts", user); // Documento del checkout dell'utente
    
      try {
        const checkoutSnap = await getDoc(checkoutRef);
    
        if (checkoutSnap.exists()) {
        
          await updateDoc(checkoutRef, {
            Modelli: [], 
          });
        
    
        } else {
          console.log("Il documento non esiste, quindi non c'è nulla da rimuovere.");
        }
      }
         catch (error) {
        console.error("Errore durante l'aggiunta al carrello:", error);
      }
    
      };
  useEffect(() => {
    if (cart) {
      document.body.style.overflow = "hidden";
    }else {
      setTimeout(() => {
        document.body.style.overflow = "visible";
  
  
  
      }, 300); // Stessa durata dell'animazione
  }
  }, [cart]);

  return(
    <div>
  <header className="flex     text-[1.3rem]  text-[#ECC5A0] font-imperial p-3 ">
  <div className='flex  sm:w-[30%] font-inter text-[0.8rem] items-center  justify-start'>
  
  {auth.currentUser? 
  <div className='flex flex-row items-center pl-2'>
        {auth.currentUser?.displayName}
      
        <div className='flex  font-inter items-center pl-2 '>

      <button onClick={logout} className='bg-[#00000000] border-l-2 pl-2 border-[#ffffff75]  text-[0.7rem]  text-white px-2  '>
        Log out
        </button>
  
    </div>   

        </div>
         :
         <div>
<button onClick={() => navigate("/login")} className='bg-[#00000000]   ml-2 px-2 text-[0.7rem] py-1 text-white  '>
        Login
        </button>
          </div>
         
         }
  </div>
      <div className='flex flex-1 items-center justify-center    text-[0.7rem] space-x-3  '>
      <Link style={{color: location.pathname == "/"? "#ffffff" : "#8b8b8b" }}  className='flex  justify-center     font-inter ' to="/">
       
       Home
   
           </Link>
           <Link style={{color: location.pathname == "/Buy"? "#ffffff" : "#8b8b8b" }}  className=' flex justify-center     font-inter ' to="/Buy">       
            Buy <span>&nbsp;</span><p className='hidden md:flex'> Models</p>
             </Link>
                <Link style={{color: location.pathname == "/order"? "#ffffff" : "#8b8b8b" }}   className='flex  justify-center     font-inter ' to="/order">
                  Order now
                  </Link>
                    <Link style={{color: location.pathname == "/terms"? "#ffffff" : "#8b8b8b" }}  className='flex  justify-center     font-inter ' to="/terms">
                     Our terms
                </Link>
           </div>
         
    <div className='flex  sm:w-[30%] font-inter text-[1.3rem] items-center  justify-end'>
  
  <button onClick={() => setcart(true)} className='px-2   text-[#ffffff]'>
          <FontAwesomeIcon  icon={faShoppingBag} />
          </button>
    </div>
    </header>

    <AnimatePresence>
      {cart && (
     
        <div className='absolute top-0  z-[1000] h-[100%] w-[100%]  shadow-lg  flex-row flex   font-inter '>
         <motion.div
       initial={{ opacity: 0, y: 0 }} // Inizia invisibile e più in basso
       animate={{ opacity: 1, y: 0 }}   // Diventa visibile e si sposta verso l'alto
       exit={{ opacity: 0, y: 0 }}    // Uscita fluida
       transition={{ duration: 0.2 }}     // Imposta la durata dell'animazione
       className='flex-1 bg-[#000000b0]'
       onClick={() => setcart(false)}
      >
        
      </motion.div>

  <motion.div
       initial={{ opacity: 0, x: 200 }} // Inizia completamente fuori a sinistra
       animate={{ opacity: 1, x: 0 }}   // Entra nella posizione originale
       exit={{ opacity: 0, x: 200 }}    // Uscita fluida
       transition={{ duration: 0.2 }} // Transizione più fluida
       className='bg-[#ffffff] text-[#000000]  px-2 py-2 text-[1.5rem]'
      >
  
    <div  className='flex h-[100%] flex-col overflow-y-auto'>
    <div className='flex flex-row pt-2 px-2'>
    <p className='w-[23rem] px-2 font-light'>Cart</p>
    <p onClick={() => setcart(false)} className=' px-2 font-light'>x</p>
    </div>
    {
      cartmodels.length>0?
      <div className='mx-6 mt-2'>
        <div className='bg-[#e2d5c36c] rounded-[5px]  '>
        <div className='flex flex-row items-center px-2 py-4'>
        <p className='text-[0.7rem] flex-1 font-semibold  '>Your Giangibuilds cart</p>
  
        <FontAwesomeIcon  onClick={() => rimuovituttimodelli()} icon={faTrash} className="px-2 text-[1rem]  text-[#3a3a3a]" />
      
          </div>
        <div className='flex flex-1 mx-2 bg-[#adadadc7] h-[1px] '></div>
        <div className='py-2'>


      {cartmodels.map((model, index) => (
   <div key={index} className='flex mt-1 flex-row items-center justify-center'>
    <div className='flex flex-row items-center flex-1 px-2 space-x-2'>
      <img src={model.immagine} className='w-[2rem] '></img> 
      <p className='text-[0.6rem]  font-semibold'>{model.Nome}</p>
      </div>
      <p className='text-[0.6rem]  font-semibold px-2 '>{formatEuro(model.prezzo)}</p>
      <button onClick={() => rimuovimodello(index)} className='text-[0.6rem]   px-2 text-red-600 font-light' >X</button>
      </div>
      ))}
      </div>
      <div className='flex  flex-1 mx-2 bg-[#adadadc7] h-[1px] '></div>

     <div className='flex flex-row mt-1 mb-2 pb-2'>
      <p className='text-[0.8rem] pt-1 font-semibold px-2 flex-1'>Total</p>
      <p className='text-[0.8rem] pt-1  font-semibold px-2 mr-1 '> {formatEuro(prezzototale)}</p>
      </div>
      </div>
      <div className='flex bg-[#e2d5c36c] rounded-[5px]  flex-col max-w-[23rem]'>
      <div className='flex flex-col  px-2 pt-4'>
        <p className='text-[0.7rem] flex-1 font-semibold  pb-3'>Choose a payment method</p>
        <div className='flex w-[100%]  bg-[#adadadc7] h-[1px] '></div>
          </div>
          
 <div className='px-5'>
 <motion.div
    whileHover={{ scale: 1.03 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
  >
  
<button  onClick={() => setselected("Card")} className="w-[100%]  mt-10 flex flex-row cardbg p-1 justify-center items-center rounded-[5px]">
  <div className='flex flex-1 space-x-1'>
  <img className='w-[2.3rem] bg-white rounded-[3px]' src={visa}></img>

  <img className='w-[2.3rem]  bg-white rounded-[3px]' src={master}></img>

  <img className='w-[2.3rem]  bg-white rounded-[3px]' src={discover}></img>
  <img className='w-[2.3rem]  bg-white rounded-[3px]' src={unionnp}></img>
  </div>
<p style={{color: selected === "Card"? "#000000": "#5f5f5f" }} className='text-[0.7rem] pr-2'> Credit card</p>
</button>
 </motion.div>
 <motion.div
    whileHover={{ scale: 1.03 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
  >
<button onClick={() => setselected("Paypal")} className="w-[100%]  mt-2 flex flex-row cardbg p-1 justify-center items-center rounded-[5px]">
  <div className='flex flex-1 space-x-1'>
  <img className='w-[2.3rem] bg-white rounded-[3px]' src={paypal}></img>

  </div>
<p  style={{color: selected === "Paypal"? "#000000": "#5f5f5f"}} className=' text-[0.7rem] pr-2'>Paypal</p>
</button>
 </motion.div>
 <button className="w-[100%]  mt-2 flex flex-row cardbg p-1 justify-center items-center rounded-[5px]">
  <div className='flex flex-1 space-x-1'>
  <div className='w-[2.3rem] border-[1px] h-[1.5rem] bg-[#4d4d4d] rounded-[3px] text-[0.9rem]'>
  <div className='w-[100%] h-[1px] bg-[#ffffff] mt-2'>

  </div>
  </div>

  </div>
<p className='text-[#5f5f5f] text-[0.7rem] pr-2'> Promotional code</p>
 </button>

 <p className='text-[0.45rem] mt-2 pb-2 px-2 md:pb-0   md:pl-0   md:text-left  text-center font-inter  max-w-[22rem]    text-[#A39C96]'> “ Hello. My name is Gianluigi and I’m a professional delevoper in many aspects, from making models, script, UIs on roblox to make 3D models to blender, and again from blender to website and app developer with react.js and firebase “ “ Hello. My name is Gianluigi and I’m a professional delevoper in many aspects, from making model... </p>

{selected=="Paypal"?
  <motion.div

whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
transition={{ type: "spring", stiffness: 300, damping: 50 }}
>
<div     className='flex w-[100%] mt-4 justify-center'>
<PayPalScriptProvider  options={initialOptions}>
<PayPalButtons
  fundingSource='paypal'
  className='w-[12rem]'
  style={{ layout: "vertical" }}

  createOrder={async () => {
 
  const user = auth.currentUser
  if (!user) {
    return null
  }
  const token = await user.getIdToken()
  const apiClient = process.env.REACT_APP_APICLIENT || "invalid";

  try{
    
  const order = await axios.post(apiClient, {
    action: 'paypalintent',
    token: token,
    modelli: soloid,
   
    });
    setpaypalerror("");
    
    return order.data.orderId;
  }catch(err: any){

    if(err.response.status=="429"){
   if (err.response.data == "Max 1"){
    setpaypalerror("Cannot make two requests in 3 seconds")
   }else{
    setpaypalerror("Too many requests, try again later")
   }
}else{
  setpaypalerror(err.response.data.message)
}
}
}}

  onApprove={(data, actions) => {
    setPaymentStatus('PENDING');
    if (actions && actions.order) {
      navigate("/success")
      return actions.order.capture().then((details) => {
        setpaypalerror("")
        setPaymentStatus('COMPLETED');
      });
    } else {
      return Promise.reject(undefined);
    }
  }}
  onError={(err: any) => {
    navigate("/cancel")
    if(paypalerror===""){
    
      setPaymentStatus('ERROR');
    }
  }}

/>
</PayPalScriptProvider>
</div>
</motion.div>
:
 <div className='flex w-[100%]  justify-center'>
 <motion.div
    whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
  

  >
<button onClick={handlePayment} className='bg-[#467427] w-[12rem] py-[0.25rem] rounded-[6px] px-3  text-[0.7rem] text-white mt-4 mb-1   '>Go to check-out <FontAwesomeIcon className='pl-1' icon={faLock} /></button>
</motion.div>
</div>
}
</div>
</div>
</div>
    : 
    <div className='flex flex-col  justify-center w-[100%] h-[100%] items-center align-middle  text-[0.7rem] '>
      <p className='font-semibold'>The cart is empty</p>
      <button onClick={() => navigate("/Buy")} className='bg-black text-white p-2 px-4 mt-2'>Go shopping</button>
    </div>
    }
    

    </div>
   
    </motion.div>
   </div>
     
   )}
    </AnimatePresence>
    </div>
  )
};

export default Header;
