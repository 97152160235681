import React, {useEffect, useState} from 'react';
import { collection, doc, getDoc, query, where, getDocs, limit, arrayUnion, updateDoc, setDoc, onSnapshot, arrayRemove} from 'firebase/firestore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import '../styles.css';
import { firestore } from '../FirebaseConfig'; 
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../FirebaseConfig';
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../FirebaseConfig";
import visa from '../images/Visa.png'; // Importa l'immagine
import master from '../images/mastercard.png'; // Importa l'immagine
import paypal from '../images/paypal.png'; // Importa l'immagine
import discover from '../images/disover.png'; // Importa l'immagine
import unionnp from '../images/unionp.png'; // Importa l'immagine
import { signOut } from 'firebase/auth';
import { useStripe, useElements} from '@stripe/react-stripe-js';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';
import Immagine from '../objects/Immagine';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from '../objects/Header';










interface Model {
  immagine: string;
}


interface PaymentStatus {
  status: 'IDLE' | 'PENDING' | 'COMPLETED' | 'ERROR';
}

interface ModelD {
  id: string,
  nome: string,
  immagine: string,
  descrizione: string
  prezzo: number,
  categoria: string
  storagePath: string,
}

const More: React.FC = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [loadingmodels, setLoadingmodels] = useState(false);
  const [loadingpage, setLoadingpage] = useState(false);
  const [carta, setcarta] = useState(true);
  const [free, setfree] = useState(false);
  const [nelcarrello, setnelcarrello] = useState(false);
  const [candownload, setcandownload] = useState(false);
  const [cancheckout, setcancheckout] = useState(true);
  const [selected, setselected] = useState("Card");
  const { articleId } = useParams();
  const [paypalerror, setpaypalerror] = useState('');
  const [models, setModels] = useState<Model[]>([]);
  const [paymentStatus, setPaymentStatus] = React.useState<PaymentStatus['status']>('IDLE');
  const [haspayed, sethaspayed] = useState(false);
  const [model, setModel] = useState<ModelD>(
    {
      id: '',
      nome: '',
      descrizione: '',
      immagine: '',
      prezzo: 0,
      categoria: '',
      storagePath: '',
    }
  ); // Stato per il modello


  useEffect(() => {
    const fetchModel = async () => {
        try {
            // Riferimento al documento specifico
            if (articleId) {
            const docRef = doc(firestore, "Modelli", articleId);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists() && docSnap.data()?.Disponibilità === true) {
                // Ottieni il riferimento all'immagine nel bucket
                const imageRef = ref(storage, `Modelli/${articleId}/${articleId}.jpg`);
                const imageUrl = await getDownloadURL(imageRef);

                // Costruisci l'oggetto modello con gli attributi
                const modelData = {
                    id: docSnap.id,
                    nome: docSnap.data()?.Nome || "Nome non disponibile",
                    descrizione: docSnap.data()?.Descrizione || "Descrizione non disponibile",
                    immagine: imageUrl,
                    prezzo: docSnap.data()?.Prezzo || 0,
                    categoria: docSnap.data()?.Categoria || "Categoria non disponibile",
                    storagePath: docSnap.data()?.Storagepath || "Path non disponibile",
                };

                setModel(modelData); // Aggiorna lo stato con i dati del modello
            } else {
             return;
            }
          } else{
            return;
          }
        } catch (error) {
            console.error("Errore nel recupero del documento:", error);
        } finally {
          setLoadingmodels(true);
        }
      
    };

    fetchModel(); // Chiama la funzione di fetch
}, []);

useEffect(() => {
  // Funzione per controllare il modello su Firebase
  if (haspayed || free) {
  const fetchModel = async () => {
    try {
      if ( !auth.currentUser || !path) {
        setcandownload(true)
        return null;
      }
            const ordersRef = collection(firestore, "Purchases");
                             // Query per cercare ordini con Stato "Pending"
                  const q = query(
                    ordersRef, 
                    where("Buyer", "==", auth.currentUser.uid), 
                    where("Id_product", "==", model.id),
                    limit(1)
                  );
                    
                             // Recupera i documenti corrispondenti
                 const querySnapshot = await getDocs(q); // Ottieni tutti i documenti nella collezione "ordini"
                 console.log(querySnapshot)
      if (!querySnapshot.empty) {
        return true; // Indica che il modello è stato trovato
      } else {
        console.log("Nessun modello trovato.");
        return false; // Nessun modello trovato
      }
    } catch (error) {
      console.error("Errore nel recupero del modello:", error);
      return false; // Gestione di errori
    }
  };

  // Avvia il polling
  const intervalId = setInterval(async () => {
    const found = await fetchModel();
    if (found || free) {
      setcandownload(true);
      clearInterval(intervalId); // Interrompe il polling se il modello è trovato
    }
   
  }, 3000);

  // Cleanup dell'intervallo quando il componente viene smontato
  return () => clearInterval(intervalId);
}
}, [haspayed, free]);



const NOME = model?.nome;
const DESC = model?.descrizione;
const id = model?.id;
const Categoria = model?.categoria;
const path = model?.storagePath;
const PREZZO = model?.prezzo;
const IMG  = model?.immagine;
const initialOptions = {
  clientId: process.env.REACT_APP_PAYPALCLIENT? process.env.REACT_APP_PAYPALCLIENT: "",
  currency: "EUR",
};

//getmodels
useEffect(() => {

  const fetchImages = async () => {
    try {
      const imagesFolderRef = ref(storage, `Modelli/${id}/More`);

      // Ottieni i riferimenti di tutte le immagini nella cartella
      const imageRefs = await listAll(imagesFolderRef);

      // Mappa su ogni riferimento per ottenere l'URL di download
      const imageUrls = await Promise.all(
        imageRefs.items.map(async (imageRef) => {
          const imageUrl = await getDownloadURL(imageRef);
          return { immagine: imageUrl }; // Creiamo un oggetto Model
        })
      );

      setModels(imageUrls); // Aggiorna lo stato con l'array di URL delle immagini
    } catch (error) {
      console.error("Errore nel recuperare le immagini:", error);
    }
  };

  fetchImages();
}, [id]); // Si aggiorna solo una volta all'inizio

  useEffect(() => {
    const fetchData = async () => {
     if (path) {
      const userName = auth.currentUser?.uid; // Ottieni il nome utente corrente
      if (!userName) {
        setLoadingpage(true)
        return;
      }
        // Accedi al documento della collezione "Modelli"
        const ordersRef = collection(firestore, "Purchases");
        // Query per cercare ordini con Stato "Pending"
const q = query(
ordersRef, 
where("Buyer", "==", userName), 
where("Id_product", "==", model.id),
limit(1)
);

        // Recupera i documenti corrispondenti
const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          sethaspayed(true);
        } 
        if (PREZZO == 0 && querySnapshot.empty) {
        setfree(true)
        
        }
        setLoadingpage(true)
    }
    };

    fetchData(); // Chiama la funzione fetchData
  }, [path]); // Aggiungi le dipendenze se necessario

  useEffect(() => {
    const user = auth.currentUser?.uid
    
    if (!user || !id) {
      console.error("Utente non autenticato o displayName mancante");
      return;
    }
  
      const userCheckoutDoc = doc(firestore, "Checkouts", user);

      const unsubscribe = onSnapshot(userCheckoutDoc, async (snapshot) => {              
      const allModelIds = snapshot.data()?.Modelli || [];
      const esiste = allModelIds.includes(id)

      console.log(esiste)
     setnelcarrello(esiste);
 
    }, (error) => {
      console.error("Errore nell'ascoltare i modelli:", error);
    });
  
    // Cleanup: chiude la connessione quando il componente viene smontato
    return () => unsubscribe();
  }, [id]); // controllo carrello


const formatEuro = (value: number) => {
  return new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  }).format(value);
};

const addToCart = async () => {
  const user = auth.currentUser?.uid;

  if (!user) {
    console.error("Utente non autenticato");
    return;
  }

  const checkoutRef = doc(firestore, "Checkouts", user); // Documento del checkout dell'utente

  try {
    const checkoutSnap = await getDoc(checkoutRef);

    if (checkoutSnap.exists()) {
      // 🔥 Se il documento esiste, aggiorna l'array "Modelli[]"
      await updateDoc(checkoutRef, {
        Modelli: arrayUnion(id), // ✅ Aggiunge l'ID senza duplicati
      });
    } else {
      // 🔥 Se il documento non esiste, lo crea con il modello aggiunto
      await setDoc(checkoutRef, {
        Modelli: [id], // ✅ Crea il vettore con il primo modello
      });
    }

    console.log(`Modello ${id} aggiunto al carrello con successo!`);
  } catch (error) {
    console.error("Errore durante l'aggiunta al carrello:", error);
  }
};


  const rimuovimodello = async () => {

const modelloid = id
console.log(modelloid)

const user = auth.currentUser?.uid;

  if (!user) {
    console.error("Utente non autenticato");
    return;
  }

  const checkoutRef = doc(firestore, "Checkouts", user); // Documento del checkout dell'utente

  try {
    const checkoutSnap = await getDoc(checkoutRef);

    if (checkoutSnap.exists()) {
    
      await updateDoc(checkoutRef, {
        Modelli: arrayRemove(modelloid), 
      });
    
      console.log(`Modello ${modelloid} rimosso dal carrello con successo!`);
    } else {
      console.log("Il documento non esiste, quindi non c'è nulla da rimuovere.");
    }
  }
     catch (error) {
    console.error("Errore durante l'aggiunta al carrello:", error);
  }

  };

  const downloadFile = (url: string) => {

    const link = document.createElement('a'); // Crea un elemento <a>
    link.href = url; // Imposta l'URL del file
    link.download = ''; // Imposta l'attributo download
    document.body.appendChild(link); // Aggiungi il link al DOM
    link.click(); // Simula un clic sul link per avviare il download
    document.body.removeChild(link); // Rimuovi il link dal DOM
  };
  const login = () => {
navigate("/login")
  };
  const handlePayment = async (event: React.FormEvent) => {
    event.preventDefault();
  if (!cancheckout) return null
setcancheckout(false)

try{
    const apiClient = process.env.REACT_APP_APICLIENT || "invalid";
    const token = await auth.currentUser?.getIdToken();
if (!token) {
 return navigate("/login")
}
    const response = await fetch(apiClient, {
        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ modelli: [model.id], action: "stripeintent", token }),
    });

    const { id } = await response.json();

    if (stripe) {
        stripe.redirectToCheckout({ sessionId: id });
    }
}catch(error)  {
  setcancheckout(true)
} finally{
  setcancheckout(true)
}
    
  };
  
  
  const handledownload = async () => {
    setcandownload(false)
    try {
     
      const user = auth.currentUser;
      if (!user) {
        console.error("Nessun utente autenticato. Assicurati di aver effettuato il login.");
        return null; // Nessun token disponibile
    }
      const token = await user.getIdToken();

      
      const apiClient = process.env.REACT_APP_APICLIENT || "invalid";
      const response = await axios.post(apiClient, {
        action: 'getfile',
        modelli: [id],
        token: token,

      });
    
      if (response.data.success) {
        const fileUrl = response.data.fileUrl; // Ottieni l'URL del file dal server
        
        // Opzionale: reindirizza l'utente all'URL del file per il download
       downloadFile(fileUrl);
    
      } else {
        // Gestisci l'errore di pagamento se il server restituisce un errore
      }
    
    } catch (error) {

     
    } finally {

      setcandownload(true);
    }
  }

     const logout =  async ()  => {
    
      signOut(auth);
      }


if (!loadingmodels || !loadingpage) {
  return <div className='h-screen w-screen bg-home2 flex justify-center items-center'>
  <div className="loader2"></div>
  </div>
} else if (NOME == "") {
  return <div className='h-screen w-screen bg-home2 flex '>
  <div className='text-white text-[2rem]'>Product not found</div>
  </div>
}


  return (
    <div className='flex flex-col h-screen  overflow-auto bg-home2  scrollbar-custom '  >
    <Elements stripe={loadStripe(process.env.REACT_APP_STRIPECLIENT? process.env.REACT_APP_STRIPECLIENT: "")}>
  <Header/>
  </Elements>
  
   
     
  
        {/* Parte iniziale */}
        {haspayed || free?
<div className='flex flex-col items-center pt-5 flex-1 space-y-[0.2rem]'>

{haspayed?
  <div className='flex flex-col items-center' >
  <p className='text-white text-[2.6rem] font-semibold  '>Thank you</p>
<p className='text-[#c4c4c4] text-[0.7rem] w-[24rem] text-center' >Thank you for your purchase! We’re thrilled you chose our product and are confident it will meet your expectations. Your support means a lot to us, and we look forward to serving you again soon!</p>
</div>

:
<div className='flex flex-col items-center'>
<p className='text-white text-[2.6rem] font-semibold  '>Get it now!</p>
<p className='text-[#c4c4c4] text-[0.7rem] w-[24rem] text-center' >Click download for get your product for free! We’re thrilled you chose our product and are confident it will meet your expectations. Your support means a lot to us, and we look forward to serving you again soon!</p>
</div>
}

<div className='flex w-full flex-row py-4 justify-center space-x-4'>
{
  candownload ? 
    <button 
      onClick={handledownload} 
      className="h-[1.6rem] px-6 bg-[#3ebe24] text-white rounded-[5px] text-[0.7rem]"
    >
      Download
    </button>
  : 
    <button 
      className="flex items-center h-[1.6rem] px-5 bg-[#2a721c] text-white rounded-[5px] text-[0.7rem]"
    >
      <p className="pr-1">Loading...</p>
      <div className="loader3"></div>
    </button>
}



<button onClick={() => navigate("/buy")} className=' h-[1.6rem]  px-6 bg-[#d48d3c] text-white rounded-[5px]  text-[0.7rem]'>Back to Buy</button>
</div>
</div>
        :
       <div className='flex flex-row justify-center  '>
<div className='lg:flex flex-col  hidden'>
  
<div className='flex w-[7rem] h-[13rem] overflow-hidden bg-slate-100 m-[1rem]'>
<img
        src={IMG} // sostituisci con il tuo URL dell'immagine
        alt="example"
        className="object-cover object-left "
      />

</div>
<div className='flex w-[7rem] h-[13rem] bg-slate-100 m-[1rem] mt-[2rem] '>
<img
        src={IMG} // sostituisci con il tuo URL dell'immagine
        alt="example"
        className="object-cover object-right "
      />
</div>


</div>
     <div className='flex-col'>





        <div className='flex justify-start flex-col md:flex-row    '>
      
        <div className='flex flex-col   items-center md:items-start      pt-5   '>
  
  <p className=' text-[2rem]  leading-[1.4rem] font-semibold    font-inter m-0 p-0 text-[#E4D2D2]'>
This is the
  </p>
 
  <p className='text-[2.6rem] leading-[3.7rem] font-semibold   font-inter     text-[#ECC5A0]'>
  {NOME}
  </p>
  <div className='flex flex-row items-center'>
  <p className='text-[0.6rem] pb-2 px-2 md:pb-0   md:pl-0   md:text-left  text-center font-inter  max-w-[22rem]    text-[#A39C96]'>
{DESC}  </p>


</div>
  <div className='sm:hidden flex flex-col pt-3 px-2'>
 <div className=' sm:hidden   inline-flex first-line space-x-2 pb-5  '>
 <div className='inline-flex first-line space-x-2 scrollbar-thin  overflow-x-auto '>

 {models.map((model) => (
  // Verifica se il prezzo del modello è maggiore di 30
  
 (
    <Immagine
      key={model.immagine} // Usare una chiave univoca per ogni elemento in lista
      immagine={model.immagine}
    />
  ) 
))}

</div>
 </div>
 </div>
  </div>

      
      
    
       </div>


       <div className='flex justify-center flex-row md:flex-row md:items-start  items-center   py-0  md:py-2 '>
      
      <div className='md:flex flex-col hidden '>
       <div className='flex w-[7rem] h-[13rem] bg-slate-100 mr-10   '>
       <img
        src={IMG} // sostituisci con il tuo URL dell'immagine
        alt="example"
        className="object-cover object-center "
      />
        </div>
       <p className=' text-[0.8rem]  leading-[1rem] font-semibold text-center mr-10 mt-4   font-inter m-0 p-0 text-[#E4D2D2]'>
Need more 
 
  </p>
  <p className=' text-[0.8rem]  leading-[1rem] font-semibold text-center mr-10    font-inter m-0 p-0 text-[#E4D2D2]'>
Informations?

  </p>  
  <button className='bg-[#A47040] rounded-[6px] text-[0.7rem] text-white mt-4  mr-10  py-[0.2rem]'>Contact me</button>
       </div>

  <div className='flex sm:flex-row flex-col sm:border-2 pl-2  sm:border-[#4A443F] rounded-[7.5px]  ' >
  
  {/* Parte 1 */}

  <div className='flex flex-col sm:w-[18rem] p-2  '>
  <p className='text-[1rem] leading-[1.5rem]  font-semibold pb-0  font-inter     text-[#fcfcfc]'>
Payment method
</p>

<div className='flex flex-col flex-1'>
<motion.div
    whileHover={{ scale: 1.03 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
  >
<button  onClick={() => setselected("Card")} className="w-[100%]  mt-10 flex flex-row cardbg p-1 justify-center items-center rounded-[5px]">
  <div className='flex flex-1 space-x-1'>
  <img className='w-[2.3rem] bg-white rounded-[3px]' src={visa}></img>

  <img className='w-[2.3rem]  bg-white rounded-[3px]' src={master}></img>

  <img className='w-[2.3rem]  bg-white rounded-[3px]' src={discover}></img>
  <img className='w-[2.3rem]  bg-white rounded-[3px]' src={unionnp}></img>
  </div>
<p style={{color: selected === "Card"? "#ffffff" : "#a3a3a3"}} className='text-[0.7rem] pr-2'> Credit card</p>
</button>
</motion.div>
<motion.div
    whileHover={{ scale: 1.03 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
  >
<button onClick={() => setselected("Paypal")} className="w-[100%]  mt-2 flex flex-row cardbg p-1 justify-center items-center rounded-[5px]">
  <div className='flex flex-1 space-x-1'>
  <img className='w-[2.3rem] bg-white rounded-[3px]' src={paypal}></img>

  </div>
<p  style={{color: selected === "Paypal"? "#ffffff" : "#a3a3a3"}} className=' text-[0.7rem] pr-2'>Paypal</p>
</button>
</motion.div>
<button className="w-[100%]  mt-2 flex flex-row cardbg p-1 justify-center items-center rounded-[5px]">
  <div className='flex flex-1 space-x-1'>
  <div className='w-[2.3rem] border-[1px] h-[1.5rem] bg-[#4d4d4d] rounded-[3px] text-[0.9rem]'>
  <div className='w-[100%] h-[1px] bg-[#ffffff] mt-2'>

  </div>
  </div>

  </div>
<p className='text-[#a3a3a3] text-[0.7rem] pr-2'> Promotional code</p>
</button>

<p className='text-[0.45rem] mt-2 pb-2 px-2 md:pb-0   md:pl-0   md:text-left  text-center font-inter  max-w-[22rem]    text-[#A39C96]'>
You have the flexibility to choose between two secure and convenient payment methods: PayPal and Stripe. Both options are widely trusted, ensuring that your transaction is processed smoothly and securely. Whether you prefer to use your PayPal account or a credit/debit card through Stripe, the checkout process is quick and easy.</p>
</div>

{
  selected==="Card" ?
 
    
    <div className='flex w-[100%] items-center justify-center align-middle  mb-1'>

{
  cancheckout ? 
  <motion.div
  whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
  transition={{ type: "spring", stiffness: 300, damping: 50 }}
  className='w-[9rem]'>
<button onClick={handlePayment} className='bg-[#467427] w-[9rem] py-[0.25rem] rounded-[6px] px-3  text-[0.7rem] text-white mt-4 mb-1   '>Go to check-out <FontAwesomeIcon className='pl-1' icon={faLock} /></button>
</motion.div>
  : 
  <motion.div
  whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
  transition={{ type: "spring", stiffness: 300, damping: 50 }}
  className='w-[9rem]'>
<button  className='bg-[#467427] flex w-[9rem] py-[0.25rem] rounded-[6px] px-3   text-[0.7rem] text-white mt-4 mb-1 justify-center items-center  '>   <div className="loader3"></div></button>
</motion.div>
}

<motion.div
    whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
    className='w-[8rem] flex justify-center '
  >
     {nelcarrello? 
<button onClick={rimuovimodello}  className='bg-[#46742700] px-5 border-[1px] border-white rounded-[6px]  text-[0.7rem] text-white mt-4 mb-1 py-[0.25rem]'>
   
<p className='text-[0.6rem]'>Remove from cart</p>

</button>
:
<button onClick={addToCart}  className='bg-[#46742700] px-5 border-[1px] border-white rounded-[6px]  text-[0.7rem] text-white mt-4 mb-1 py-[0.25rem]'>
 
<p>Add to cart</p>
  
</button>
}
</motion.div>
</div>


  :
  <div className="flex w-[100%] items-center  justify-center align-middle" >
  <motion.div

  whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
  transition={{ type: "spring", stiffness: 300, damping: 50, }}
  className='flex-1'
>
<PayPalScriptProvider  options={initialOptions}>
  <PayPalButtons
    fundingSource='paypal'
    style={{ 
      layout: "horizontal",  // Pulsante orizzontale moderno
      color: "blue",         // Colore blu moderno di PayPal
      height: 38,         // Altezza più grande per un look più premium
    }}

    createOrder={async () => {
   
    const user = auth.currentUser
    if (!user) {
      return null
    }
    const token = await user.getIdToken()
    const apiClient = process.env.REACT_APP_APICLIENT || "invalid";

    try{
      
    const order = await axios.post(apiClient, {
      action: 'paypalintent',
        modelli: [id],
        token: token, 
        path: path,
      });
      setpaypalerror("");
      return order.data.orderId;
    }catch(err: any){
      if(err.response.status=="429"){
     if (err.response.data == "Max 1"){
      setpaypalerror("Cannot make two requests in 3 seconds")
     }else{
      setpaypalerror("Too many requests, try again later")
     }
  }else{
    setpaypalerror(err.response.data.message)
  }
}
}}

    onApprove={(data, actions) => {
      setPaymentStatus('PENDING');
      if (actions && actions.order) {
        return actions.order.capture().then((details) => {
          setpaypalerror("")
          setPaymentStatus('COMPLETED');
          sethaspayed(true);
        });
      } else {
        return Promise.reject(undefined);
      }
    }}
    onError={(err: any) => {
      if(paypalerror===""){
        setPaymentStatus('ERROR');
      }
    }}
  
  />
</PayPalScriptProvider>

</motion.div>
<motion.div
    whileHover={{ scale: 1.1 }} // Quando si passa sopra, l'elemento aumenta di dimensione
    transition={{ type: "spring", stiffness: 300, damping: 50 }}
 className='w-[8rem] flex justify-center'

  >
   {nelcarrello? 
<button onClick={rimuovimodello} className='bg-[#46742700] px-5 border-[1px] border-white rounded-[6px]  text-[0.7rem] text-white  mb-1 py-[0.25rem]'>
   
<p className='text-[0.6rem]' >Remove from cart</p>

</button>
:
<button onClick={addToCart}  className='bg-[#46742700] px-5 border-[1px] border-white rounded-[6px]  text-[0.7rem] text-white  mb-1 py-[0.25rem]'>
 
<p >Add to cart</p>
  
</button>
}
</motion.div>
</div>



}







<p className='text-[0.45rem] mt-2 pb-2 px-2 md:pb-0   md:pl-0   md:text-left  text-center font-inter  max-w-[22rem]    text-[#A39C96]'>
You can either get the model immediately or add it to your cart for later purchase. The choice is yours!</p>


</div>
{/* Divisore */}



    <div className='flex border-[1px] border-[#4A443F]'>
    
    </div> 





{ carta?
<div className='flex flex-col  items-center sm:items-start p-2 '>

<p className='text-[1rem] leading-[1.5rem]   font-semibold pb-0  font-inter     text-[#fcfcfc]'>
Your order
</p>

<img src={IMG} alt="Logo" className='w-[12rem] pt-4  px-2  brightness-[90%]  '/> {/* Inserisci l'immagine */}
<div className='flex w-[100%] items-center justify-center'>
<p className=' text-white text-[1.6rem]'>{NOME}</p>
</div>
<p className='flex text-[0.4rem] sm:w-[12rem] w-[14rem] justify-center    pt-2  p-3 md:text-left  text-center font-inter  sm:max-w-[23rem]    text-[#777777]'>
You can review our terms by visiting the Terms section. For details on payment terms and related policies, please check the Payment Terms section.
</p>
<div className='flex  mb-4  px-3 py-2 sm:w-[12rem]  w-[100%]  flex-col space-y-3  text-white text-[0.6rem]'>
<div className="flex flex-row ">

<p className='flex flex-1'>Title:</p>

{NOME}

</div>
<div className="flex flex-row ">

<p className='flex flex-1'>Category:</p>

{Categoria}

</div>
<div className="flex flex-row ">

<p className='flex flex-1'>Price:</p>

{formatEuro(PREZZO)}

</div>





    

</div>

</div>
:
<div></div>
}



  </div>  

      
    
  
     </div>
     </div>
     </div>



  }



    


<div className='flex pt-4  px-2 items-center '>
   <div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '>
   </div>
     </div>

     <p className='text-[2rem] hidden sm:flex leading-[3.7rem] font-semibold   font-inter    pl-0   sm:pl-[4rem] text-[#ffffff]'>
 Gallery of {NOME}
 </p>
 <div className=' hidden   sm:inline-flex first-line space-x-2 pl-14  '>
 <div className='inline-flex first-line space-x-2 scrollbar-thin  overflow-x-auto scrollbar-custom '>

 {models.map((model) => (
  // Verifica se il prezzo del modello è maggiore di 30
  
 (
    <Immagine
      key={model.immagine} // Usare una chiave univoca per ogni elemento in lista
      immagine={model.immagine}
    />
  ) 
))}
</div>
 </div>










 <div className='flex justify-center mt-2 text-white text-[0.6rem] '>
 © 2025 Giangibuilds from Gianluigi Izzo
    </div>
    </div>
  );
}

export default More;


