import React, {useEffect, useState} from 'react';
import {Firestore, collection, doc, getDocs, onSnapshot} from 'firebase/firestore'
import '../styles.css';
import { firestore } from '../FirebaseConfig'; 
import '../App.css';
import { auth } from '../FirebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import blender from '../images/blender.jpg'; // Importa l'immagine
import { useNavigate } from 'react-router-dom';
import options from './altristili';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from '../objects/Header';



const logout =  async ()  => {

  signOut(auth);
  }
  
const Tos: React.FC = () => {
 const navigate = useNavigate();
  return (
    <div className='flex flex-col h-screen  overflow-auto bg-home2  scrollbar-custom  px-2 '  >
   
   <Elements stripe={loadStripe(process.env.REACT_APP_STRIPECLIENT? process.env.REACT_APP_STRIPECLIENT: "")}>
  <Header/>
  </Elements>

    
  
<div className='flex font-semibold text-[#ffffff] text-[3rem] font-inter justify-center'>
Terms & Privacy of Giangibuilds
</div>

<div className='flex flex-col  justify-start sm:px-32 pt-2'>

<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1.2rem] font-inter justify-center'>
Last updated: 20/02/2025
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
Welcome to GiangiBuilds. By accessing and using our services, you agree to comply with these Terms and Conditions. Please read them carefully before proceeding.
</div>
</div>



<div className='flex flex-col py-3 pt-10 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
1. Introduction
</div>
<div className='flex  text-[#c7c7c7] text-[0.75rem] font-inter text-start'>
1.1 This website is operated by GiangiBuilds, founded by Gianluigi Izzo.
</div>
<div className='flex  text-[#c7c7c7] text-[0.75rem] font-inter text-start'>
1.2 By using the Website, you confirm your acceptance of these Terms. If you do not agree, please refrain from using our Website.
</div>
</div>



<div className='flex flex-col py-3  space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
2. Access and Use
</div>
<div className='flex  text-[#c7c7c7] text-[0.75rem] font-inter text-start'>
2.1 You may use the Website for personal and non-commercial purposes only.
</div>
<div className='flex  text-[#c7c7c7] text-[0.75rem] font-inter text-start'>
2.2 Prohibited activities include: Posting illegal, offensive, or harmful content or attempting to disrupt or overload the Website's infrastructure.
</div>
<div className='flex  text-[#c7c7c7] text-[0.75rem] font-inter text-start'>
2.3 We reserve the right to suspend or terminate access to the Website without notice.
</div>
</div>


<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
3. User Accounts
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
3.1 All features require account registration. You are responsible for keeping your credentials confidential.
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
3.2 The information you provide must be accurate and up to date.
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
3.3 GiangiBuilds is not liable for unauthorized account usage due to your negligence.
</div>
</div>

<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
4. Products and Services
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
4.1 GiangiBuilds offers 3D models, scripts, and other digital assets, including custom commissions for Roblox and Blender. While we strive for accurate product descriptions, errors may occur.
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
4.2 Prices of our products are displayed in the &nbsp; <Link to="/Buy" className='text-blue-500' > buy section</Link> &nbsp; and do not include applicable taxes, which will be visible during the checkout process.</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
4.3 We reserve the right to adjust prices or discontinue products without prior notice.
</div>
</div>

<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
5. Payments and Taxes
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
5.1 Payments are processed through third-party providers, such as Stripe or PayPal. By providing payment information, you authorize these providers to handle your transactions.
</div>

<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
5.2 Customers are responsible for ensuring their payment methods are valid.
</div>
</div>


<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
6. Refund Policy
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
6.1 Refunds are not accepted for digital products. However, a refund may be issued if the product delivered is completely different from what is advertised on the website. In such cases, customers must provide sufficient evidence to support their claim, and all refund requests will be evaluated on a case-by-case basis.</div>
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
6.2 Refund Policy for Custom Commissions
For custom orders, such as Roblox and Blender models, refunds are available under the following conditions:
<br/><br/>
-100% refund → if the order is canceled within 12 hours of payment.
<br/>
-80% refund → if the order is canceled within 24 hours of payment.
<br/>
-50% refund → if the order is canceled within 48 hours of payment.
<br/>
- No refund → after 48 hours from payment, refunds will no longer be available unless granted at our discretion.
<br/><br/>
We reserve the right to grant exceptional refunds at our sole discretion.
</div>
<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
7. Intellectual Property
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
7.1 All content on GiangiBuilds (text, graphics, logos, software) is protected by copyright and is the property of GiangiBuilds or its licensors.
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
7.2 You may not copy, distribute, or modify content without prior written consent.
</div>
</div>



<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
8. Limitation of Liability
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
8.1 GiangiBuilds is provided "as is" and "as available." We do not guarantee uninterrupted or error-free service.
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
8.2 We are not liable for any damages resulting from the use or inability to use our Website or services.
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
8.3 External links on our Website are provided for convenience and are not endorsements.
</div>
</div>

<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
9. Changes to Terms
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
9.1 We may update these Terms periodically. Updates will be posted on this page with a new “Last Updated” date.
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
9.2 Continued use of GiangiBuilds after changes constitutes acceptance of the new Terms.
</div>
</div>

<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
10. Miscellaneous
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
13.1 Force Majeure: We are not liable for delays or failures due to causes beyond our control.
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
13.2 Severability: If any provision is found invalid, the remaining provisions remain enforceable.
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
13.3 Entire Agreement: These Terms constitute the entire agreement between you and GiangiBuilds.
</div>
</div>



<div className='flex font-semibold text-[#ffffff] text-[3rem] font-inter justify-center'>
Privacy policy
</div>





<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
1. Personal Information
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
We may collect personal information that you provide directly to us, including:

Name, email address, and contact details when you sign up.
Payment information during purchases (processed securely by third-party payment providers).
Other information you choose to provide (e.g., support inquiries).
</div>

</div>

<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
1.1 Automatic Information
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
We may automatically collect data such as:

IP address, browser type, and device information.
Pages visited, time spent on pages, and other analytical data.
</div>

</div>

<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
1.2  Provide Our Services
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
We use your information to:

Process orders and payments.
Deliver services and products.
Respond to customer inquiries and provide support.
</div>

</div>
<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
1.3 Analytics and Improvements
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
We analyze user data to improve website functionality, optimize services, and enhance the user experience.
</div>

</div>
<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
1.4 How We Share Your Information
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
We do not sell or rent your information. However, we may share it with:

Service Providers: Trusted third-party providers for payment processing, email communication, and hosting.
Legal Compliance: Authorities or regulators when required by law or to protect our rights.
Business Transfers: In case of a merger, acquisition, or sale of assets.
</div>

</div>


<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
1.5 Data Security
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
We implement industry-standard security measures to protect your personal data. However, no system is completely secure, and we cannot guarantee absolute security.
</div>

</div>

<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
1.6 Your Rights
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
Depending on your location, you may have the right to:

Access, correct, or delete your personal data.
Restrict or object to processing.
Withdraw consent where processing is based on consent.
To exercise these rights, contact us at support@giangibuilds.com.</div>
</div>

<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
1.7 Third-Party Links
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
Our website may contain links to third-party websites. We are not responsible for their privacy practices, and we encourage you to review their privacy policies
</div>

</div>


<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
1.8 Children's Privacy
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
Our services are not intended for individuals under the age of 13. We do not knowingly collect data from children.
</div>

</div>



<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
1.9  Changes to This Privacy Policy
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
We may update this policy from time to time. The updated policy will be posted on this page with the "Last Updated" date.
</div>
</div>
<div className='flex flex-col py-3 space-y-0'>
<div className='flex font-semibold text-[#ffffff] text-[1rem] font-inter justify-start'>
2. Contact Us
</div>
<div className='flex  text-[#c7c7c7] text-[0.7rem] font-inter text-start'>
If you have any questions about this Privacy Policy or how your data is handled, please contact us at:
Email: support@giangibuilds.com
</div>
</div>


















</div>

<div className='flex justify-center mt-2 text-white text-[0.6rem] '>
© 2025 Giangibuilds from Gianluigi Izzo
    </div>
  </div>
   
  );
}
export default Tos;


