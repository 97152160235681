import React, {useEffect, useState} from 'react';
import {Firestore, Timestamp, collection, doc, getDoc, onSnapshot, where, query, orderBy} from 'firebase/firestore'
import '../styles.css';
import { firestore } from '../FirebaseConfig'; 
import '../App.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Order from '../objects/Order';
import blender from '../images/blender.jpg'; // Importa l'immagine
import { storage } from "../FirebaseConfig";
import options from './altristili';
import { auth} from '../FirebaseConfig';
import { format, toDate } from 'date-fns';
import { time } from 'console';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from '../objects/Header';
import axios from 'axios';



interface Model {
id: string,
timestamp: string,
transactionId: string,
}
interface data {
  Nome: string,
  id: string,
  Descrizione: string,
  Prezzo: string,
  immagine: string,
  Categoria: string,
  data: string,
}
const Cronology: React.FC = () => {

  const [orders, setorders] = useState<Model[]>([]);
  const [data, setdata] = useState<data | null>(null);
  const navigate = useNavigate();


  const convert = (timestamp: Timestamp): string=> {
    const date = timestamp.toDate();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mese inizia da 0
    const year = date.getFullYear();
   const datafinale = `${day}/${month}/${year}`;
  return datafinale
  }

  const handleOrderClick = async (data: object) => {
    try {
      const { id, timestamp } = data as { id: string; timestamp: string };
      const modelDocRef = doc(firestore, "Modelli", id); // Ottieni il riferimento al documento
  
      // Ottieni il documento
      const docSnap = await getDoc(modelDocRef);
      if (!docSnap.exists()) {
        console.error("Documento non trovato!");
        return;
      }
  
      // Ottieni l'immagine dalla storage
      const imageRef = ref(storage, `Modelli/${docSnap.id}/${docSnap.id}.jpg`);
      const imageUrl = await getDownloadURL(imageRef);
  
      // Imposta i dati
      setdata({
        Nome: docSnap.data()?.Nome || "Nome non disponibile",
        id: docSnap.id|| "Nome non disponibile",
        Descrizione: docSnap.data()?.Descrizione || "Descrizione non disponibile",
        Prezzo: docSnap.data()?.Prezzo || "Prezzo non disponibile",
        immagine: imageUrl || "Immagine non disponibile",
        Categoria: docSnap.data()?.Categoria || "Categoria non disponibile",
        data: timestamp,
      });
  
    } catch (error) {
      console.error("Errore nell'elaborazione del modello:", error);
    }
  };
  const downloadFile = (url: string) => {

    const link = document.createElement('a'); // Crea un elemento <a>
    link.href = url; // Imposta l'URL del file
    link.download = ''; // Imposta l'attributo download
    document.body.appendChild(link); // Aggiungi il link al DOM
    link.click(); // Simula un clic sul link per avviare il download
    document.body.removeChild(link); // Rimuovi il link dal DOM
  };
  const handledownload = async () => {
console.log(data?.id)
    try {
     
      const user = auth.currentUser;
      if (!user) {
        console.error("Nessun utente autenticato. Assicurati di aver effettuato il login.");
        return null; // Nessun token disponibile
    }
      const token = await user.getIdToken();

      
      const apiClient = process.env.REACT_APP_APICLIENT || "invalid";
      const response = await axios.post(apiClient, {
        action: 'getfile',
        modelli: [data?.id],
        token: token,
      });
    
      if (response.data.success) {
        const fileUrl = response.data.fileUrl; // Ottieni l'URL del file dal server
        
        // Opzionale: reindirizza l'utente all'URL del file per il download
       downloadFile(fileUrl);
    
      } else {
        // Gestisci l'errore di pagamento se il server restituisce un errore
      }
    
    } catch (error) {

     
    } 
  }

//getordini
useEffect(() => {
  const user = auth.currentUser?.uid
  
  if (!user) {
    console.error("Utente non autenticato o displayName mancante");
    return;
  }
  console.log(user)
  const ModelsCollection =  collection(firestore, "Purchases");
                           // Query per cercare ordini con Stato "Pending"
      const q = query(
        ModelsCollection, 
                  where("Buyer", "==", user), 
                  orderBy("Timestamp", "desc")
                );

  // Ascolta i cambiamenti in tempo reale nella collezione "Modelli"
  const unsubscribe = onSnapshot(q, async (snapshot) => {
  const orders = await Promise.all(snapshot.docs.map(async (Doc) => {
     
      return {
      id: Doc.data()?.Id_product,
      timestamp:  convert(Doc.data()?.Timestamp),
      transactionId: Doc.data()?.Transaction_Id || "Nome non disponibile",
   };

   }));
    setorders(orders); // Aggiorna lo stato con i modelli aggiornati
  }, (error) => {
    console.error("Errore nell'ascoltare i modelli:", error);
  });

  // Cleanup: chiude la connessione quando il componente viene smontato
  return () => unsubscribe();
}, []);
console.log(orders)

const logout =  async ()  => {
  signOut(auth);
  }

  return (
    <div className='flex flex-col h-screen  overflow-auto bg-home2  scrollbar-custom px-2 '  >
       <Elements stripe={loadStripe(process.env.REACT_APP_STRIPECLIENT? process.env.REACT_APP_STRIPECLIENT: "")}>
  <Header/>
  </Elements>
<div className='flex flex-col sm:flex-row pt-4 justify-center'>
  <div className='flex flex-col'>
<p className='text-white text-[0.9rem] font-thin font-inter'>Cronology boughts</p>
<div className='flex flex-col border-[1px]  h-[25rem] bg-[#0000004b] rounded-[5px] border-[#818080] text-white'>

<div className='flex flex-row justify-center text-[0.7rem] border-b-[1px] border-[#818080]  font-thin'>
<p className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem]  text-center'>Title of order</p>
<p  className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem] text-center'>Type</p>
<p className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem] text-center'>Date</p>
<p className='w-[5rem] sm:w-[5rem] md:w-[rem] lg:w-[7rem] xl:w-[9rem] text-center'>State</p>
</div>
<div className='flex flex-col overflow-y-scroll scrollbar-custom'>
{orders.map((model) => (
  // Verifica se il prezzo del modello è maggiore di 30

    <Order
      key={model.id}
      id={model.id} // Usare una chiave univoca per ogni elemento in lista
      timestamp={model.timestamp}
      transactionId={model.transactionId}
      onClick={() => handleOrderClick(model)}
    />
 
))}

</div>






</div>
</div>
{ data? 
  <div className='flex flex-col w-[100%] sm:w-[13rem] border-[1px] px-2 sm:ml-2 mt-2 sm:mt-0 rounded-[5px] border-[#818080]  items-center '>
<div className='flex  w-[10rem] bg-slate-100 rounded-[5px] mt-[2rem] '>
<img
        src={data.immagine} // sostituisci con il tuo URL dell'immagine
        alt="example"
        className=" rounded-[5px] "
      />
</div>
<div className='flex-1'>
<p className='flex text-[0.8rem]  justify-center    pt-2  md:text-left  text-center font-inter      text-[#ffffff]'>
{data.Nome}
</p>

<p className='flex text-[0.5rem]  justify-center    pt-1  pb-2 md:text-left  text-center font-inter      text-[#c0c0c0]'>
{data.Descrizione}
</p>

<div className='flex   items-center '>
 <div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '></div>
   </div>
   <div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start      p-[0.4rem] md:text-left   font-inter    text-[#aaaaaa]'>
Name
</p>
<p className='flex text-[0.6rem]   justify-center      p-[0.4rem] md:text-left  text-center font-inter      text-[#ffffff]'>
{data.Nome}
</p>
</div>
<div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start      p-[0.4rem] md:text-left   font-inter    text-[#aaaaaa]'>
Date of payment
</p>
<p className='flex text-[0.6rem]   justify-center      p-[0.4rem] md:text-left  text-center font-inter      text-[#ffffff]'>
{data.data}
</p>
</div>

<div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start     p-[0.4rem] md:text-left   font-inter      text-[#aaaaaa]'>
Category
</p>
<p className='flex text-[0.6rem]   justify-center      p-[0.4rem] md:text-left  text-center font-inter      text-[#ffffff]'>
{data.Categoria}
</p>
</div>
<div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start     p-[0.4rem] md:text-left   font-inter      text-[#aaaaaa]'>
Price
</p>
<p className='flex text-[0.6rem]   justify-center      p-[0.4rem] md:text-left  text-center font-inter      text-[#ffffff]'>
{data.Prezzo + " €"}
</p>
</div>
<div className='flex flex-row'>
<p className='flex flex-1 text-[0.6rem]  text-start     p-[0.4rem] md:text-left   font-inter      text-[#aaaaaa]'>
State
</p>
<p className='flex text-[0.6rem]   justify-center      p-[0.4rem] md:text-left  text-center font-inter      text-[#ffffff]'>
Completed
</p>
</div>



<div className='flex   items-center '>
 <div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '></div>
   </div>

  




    

</div>
<div className='flex  pb-2 justify-center items-end'>
<button onClick={() => handledownload()}  className='bg-[#2c2cd0] rounded-[6px] px-5 text-[0.7rem] text-white mt-4    py-[0.15rem]'>Download</button>
</div>
</div>
: 
<div className='flex flex-col border-[1px] px-[2.4rem] justify-center text-[#bebebe] ml-2 rounded-[5px] border-[#818080]  items-center '>
  Nothing selected...
</div>
}

</div>

    <div className='flex flex-1 items-end justify-center mt-2 text-white text-[0.6rem] '>
    © 2025 Giangibuilds from Gianluigi Izzo
    </div>
  </div>
   
  );
}
export default Cronology;


