import React, {useEffect, useState, useRef} from 'react';
import '../styles.css';
import { motion } from "framer-motion";
import '../App.css';
import { Link } from 'react-router-dom';
import { Buffer } from "buffer";
import logo from "../images/Frame 6.png"
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faArrowLeft,  faArrowRight, faL  } from '@fortawesome/free-solid-svg-icons';
import blender from '../images/blender.jpg'; // Importa l'immagine
import blender2 from '../images/blender2.png'; // Importa l'immagine
import roblox from '../images/image_33.webp'; // Importa l'immagine
import { useDropzone } from 'react-dropzone';
import {  doc, setDoc, getDocs, collection, query, where,  } from "firebase/firestore";
import {  ref, uploadBytes ,  listAll, getBlob, getDownloadURL } from "firebase/storage";
import Immagine from '../objects/Immagine';
import {firestore, auth, storage} from "../FirebaseConfig";
import { title } from 'process';
const IMMAGINE1 = roblox
const IMMAGINE2 = blender
const IMMAGINE3 = blender2
const Createorder: React.FC = () => {
    const navigate = useNavigate();
    
  const [currentImage, setCurrentImage] = useState<string>(blender); // Immagine iniziale
  const [scrollDirection, setScrollDirection] = useState<string>("down"); // Direzione dello scroll
  const [errore, setessore] = useState(false);
  const [inupdate, setinupdate] = useState(false); 
  const [loading, setisloading] = useState(false); 
  const scrollableDivRef = useRef<HTMLDivElement>(null); // Riferimento al div scorrevole
  const lastScrollTop = useRef<number>(0); // Per tracciare la posizione precedente dello scroll
  const [inputValue, setInputValue] = useState({
    Titolo: "Title",
    Descrizione: "Enter an in-depth description of everything you want us to include",
  });
  // Definizione del tipo per i file accettati

  // Utilizzo di useDropzone con i tipi corretti
  const [files, setFiles] = useState<File[]>([]);
  const [category, setcategory] = useState("Roblox");
  const onDrop = (acceptedFiles: File[]) => {
    // Aggiungi i file accettati all'array esistente e genera l'anteprima
    setFiles(prevFiles => [
      ...prevFiles, // Mantieni i file esistenti
      ...acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file), // Aggiungi l'anteprima
        })
      ),
    ]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': [], // Accetta solo immagini
    },

    
  });


  const handleScroll = () => {
    if (!scrollableDivRef.current) return;

    const scrollY = scrollableDivRef.current.scrollTop; // Ottieni la posizione dello scroll del div
    const direction = scrollY > lastScrollTop.current ? "down" : "up"; // Determina la direzione dello scroll
    setScrollDirection(direction);
    lastScrollTop.current = scrollY; // Aggiorna la posizione precedente dello scroll

    // Logica per cambiare immagine in base alla posizione dello scroll
    if (scrollY < 500) {
      setCurrentImage(IMMAGINE2);
    } else if (scrollY >= 500 && scrollY < 1200) {
      setCurrentImage(IMMAGINE3);
    }else{
      setCurrentImage(IMMAGINE1);
    }
  };

  const handleinputchange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {name, value} = event.target;
    console.log(value)
      setInputValue({
        ...inputValue,
        [name]: value
      });
    }
    
    const handleSubmit = async () => {
      if(!loading){
        setisloading(true);
      if (files.length==0 || inputValue.Titolo.length == 0 || inputValue.Descrizione.length == 0) {
        setessore(true)
        setisloading(false);
        return 
      }
      const processedFiles = [];
      for (const file of files) {
        const arrayBuffer = await file.arrayBuffer();
        processedFiles.push({
          name: file.name,
          type: file.type,
          size: file.size,
          data: Buffer.from(arrayBuffer).toString("base64"), // Converte i dati in Base64
        });
      }
         const user = auth.currentUser;
            if (!user) {
              throw new Error("User not authenticated");
            }
            const token = await user.getIdToken();
            const apiClient = process.env.REACT_APP_APICLIENT2 || "invalid";
      const response = await axios.post(apiClient, {
        action: "createrequest",
        Categoria: category, 
        Titolo: inputValue.Titolo,
        token: token, 
        Descrizione: inputValue.Descrizione, 
        immagini: processedFiles, 
      });
      if (response.data.success) {
        navigate(`/checkoutorder`)
      }
      setisloading(false);
    }
    };

  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;

    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleRemoveFile = (indexToRemove: number) => {
    setFiles((prevFiles) => {
      // Filtra l'array e rimuove il file selezionato
      
      const updatedFiles = prevFiles.filter((_, index) => index !== indexToRemove);

      // Revoca l'URL di anteprima per evitare perdite di memoria
      return updatedFiles;
    });
  };
  useEffect(() => {
    const checkPendingOrder = async () => {
      try {
        const uid = auth.currentUser?.uid; // Ottieni l'ID dell'utente attualmente autenticato
        if (!uid) {
          console.log("Nessun utente autenticato");
          return;
        }

        // Riferimento alla collezione degli ordini per l'utente
          const ordersRef = collection(firestore, "Orders");
           
                   // Query per cercare ordini con Stato "Pending"
                   const q = query(
                    ordersRef, 
                    where("Buyer", "==", uid), 
                    where("Stato", "==", 0)
                );

        // Recupera i documenti corrispondenti
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Se ci sono documenti, prendi il primo (o gestiscili tutti)
          setinupdate(true)
          const firstPendingOrder = querySnapshot.docs[0].data();
          setInputValue({
            ...inputValue, // Mantieni gli altri valori invariati
            Titolo:firstPendingOrder.Titolo,
            Descrizione: firstPendingOrder.Descrizione, // Aggiorna solo il Titolo
          })
          setcategory( firstPendingOrder.Categoria) // Inizializza come array di stringhe

    const uid = auth.currentUser?.uid;
              if (uid) {
    
                const storageRef = ref(storage, `Orders/${uid}/${querySnapshot.docs[0].id}`);  // Ottieni tutti i file nella cartella  // Ottieni riferimento alla cartella
                const result = await listAll(storageRef); // Ottieni tutti i file nella cartella
            
                // Mappa ogni file per creare un oggetto `FileWithPreview`
                const filePromises = result.items.map(async (itemRef) => {
                  const fileURL = await getDownloadURL(itemRef); // Ottieni l'URL del file
                  const response = await fetch(fileURL, { mode: 'cors' });
                  const blob = await response.blob();
            
                  // Crea un oggetto File e aggiungi la preview
                  const file = new File([blob], itemRef.name, { type: blob.type });
                  const preview = URL.createObjectURL(blob); // Genera URL di anteprima
            
                  // Ritorna un oggetto FileWithPreview
                  return Object.assign(file, { preview });
                });
            
                // Risolvi tutte le promesse
                const filesWithPreviews = await Promise.all(filePromises);
        
                // Aggiorna lo stato con i file ottenuti
                setFiles(filesWithPreviews);
              }
        } else {
          console.log("Nessun ordine in sospeso trovato");
        }
      } catch (error) {
        console.error("Errore durante la verifica degli ordini:", error);
      }
    };

    checkPendingOrder(); // Chiama la funzione per verificare gli ordini
  }, []);


  return (
    <div className='flex flex-row h-screen bg-[#313131]    '  >
    
    <div className="absolute sm:flex hidden h-screen w-screen overflow-hidden   ">


  <button onClick={() => navigate("/order")}className='absolute p-2 text-[0.9rem] z-50 text-white'>
  <FontAwesomeIcon icon={faArrowLeft} className="mr-1 text-[#ffffff]" /> Back 
  </button>

    <motion.div
          key={currentImage}
          initial={{ opacity: 0, y: scrollDirection === "down" ? 70 : -70 }} // Animazione inversa rispetto alla direzione dello scroll
          animate={{
            opacity: 1,
            y: 0, // L'immagine torna in posizione neutrale
          }}
          transition={{ duration: 0.5 }}
        >
          <img
            src={currentImage} // Cambia immagine in base all'indice
            alt="Dynamic"
            className="object-cover object-center w-screen h-screen"
          />
        </motion.div>
     
  <div className='flex flex-1'></div>
   
      </div>
  
  <div className='sm:flex hidden flex-1'></div>
    <div className='flex flex-col sm:w-[20rem] w-[100%] z-10 shadow-custom opacity-2   bg-orders2  overflow-y-auto scrollbar-custom2   '  ref={scrollableDivRef}>
 


<p className='text-white p-2  font-light font-imperial  '>Giangibuilds - Commissions</p>
  {/* DIVISORE*/}
<div className='flex   px-2 items-center '>
<div className='flex flex-1 bg-[#6d6d6dc7] h-[1px] '>
  </div>
</div>
  {/* CHOOSE CATEGORY */}
<div className='flex flex-col pl-4 pr-4  font-inter font-light'>


<p className='text-[1.6rem] text-white ] pt-20'>{inupdate? "Modify the" : "Choose a"  } category</p>

<p className='text-[0.7rem] pr-4 text-[#E3E3E3] '>{inupdate? "Modify the" : "Choose a"  } category for the thing that you are gonna ask to us</p>
<div>
<button onClick={() => navigate("/terms")} className='bg-[#525252] rounded-[5px] mt-2 px-3 flex justify-center text-[0.7rem] py-[0.30rem]  text-[#ebebeb] items-center '> <FontAwesomeIcon icon={faCircleInfo} className="mr-1 text-[#949090]" />Our terms</button>
</div>
<p className='text-[1rem] text-white pt-4 pb-2'>Category</p>

<div className='flex flex-row  space-x-3'>

<button onClick={() => setcategory("Blender")} style={category==="Blender"? {border: "2px solid blue"} : {} }  className="relative   w-[10rem] h-[4rem]">
  <img 
    src={blender}  
    className="w-full opacity-65 h-full object-cover object-right" 
    alt="Blender"
  />
  <div className="absolute top-0 left-0 w-full h-full  flex items-center justify-center text-white font-bold">
   Blender stuffs
  </div>
</button>
<button onClick={() => setcategory("Roblox")} style={category==="Roblox"? {border: "2px solid blue"} : {} }  className="relative  w-[10rem] h-[4rem]">
  <img 
    src={IMMAGINE1}  
    className="w-full opacity-65 h-full object-cover object-right" 
    alt="Blender"
  />
  <div className="absolute top-0 left-0 w-full h-full  flex items-center justify-center text-white font-bold">
   Roblox stuffs
  </div>
</button>
</div>

<div className='border-[1px]  mt-3 p-1 border-[#888] '>
<p className='text-[0.7rem] text-[#E3E3E3]  '>{inupdate? "Modify the" : "Choose a"  }category for the thing that you are gonna ask to us</p>
</div>




</div>




<div className='flex flex-col pl-4 pr-4 pt-20   font-inter font-light'>


<p className='text-[1.6rem] text-white ] pt-20'>{inupdate? "Modify the" : "Insert some"  }  info</p>

<p className='text-[0.7rem] pr-4 text-[#E3E3E3] '>{inupdate? "Modify the informations" : "Insert some informations"  }  for the thing that you are gonna ask to us</p>
<div>
<button onClick={() => navigate("/terms")} className='bg-[#525252] rounded-[5px] mt-2 px-3 flex justify-center text-[0.7rem] py-[0.30rem]  text-[#ebebeb] items-center '> <FontAwesomeIcon icon={faCircleInfo} className="mr-1 text-[#949090]" />Our terms</button>
</div>

<input
        type="text"
        className={"bg-transparent text-[1.7rem] mt-4  text-white border-b-[2px] border-[#8b8b8b]  mb-2"}
        value={inputValue.Titolo} // Lega l'input allo stato
          name="Titolo"
        onChange={handleinputchange} // Gestore per il cambiamento dell'input
      />
<textarea
  className=" overflow-y-scroll  text-[0.4rem] w-full h-[10rem] scrollbar-custom2 bg-[#0202022c] text-white   resize-none"
  value={inputValue.Descrizione} // Lega il valore allo stato
  name="Descrizione"
  onChange={handleinputchange} // Gestore per il cambiamento dell'input
  placeholder="Inserisci la descrizione..."
></textarea>

<div className='border-[1px]  mt-3 p-1 border-[#888] '>
<p className='text-[0.7rem] text-[#E3E3E3]  '>{inupdate? "Modify the informations" : "Insert some informations"  }  for the thing that you are gonna ask to us</p>
</div>




</div>


<div className='flex flex-col pl-4 pr-4 pt-[6rem]    font-inter font-light'>


<p className='text-[1.6rem] text-white ] pt-20'>{inupdate? "Update the references" : "Put some references"  }</p>

<p className='text-[0.7rem] pr-4 text-[#E3E3E3] '>{inupdate? "Update the references" : "Put some references"  } for the thing that you are gonna ask to us</p>
<div className='mb-4'>
<button onClick={() => navigate("/terms")} className='bg-[#525252] rounded-[5px] mt-2 px-3 flex justify-center text-[0.7rem] py-[0.30rem]  text-[#ebebeb] items-center '> <FontAwesomeIcon icon={faCircleInfo} className="mr-1 text-[#949090]" />Our terms</button>
</div>

    <div
      {...getRootProps()}
      className='flex rounded-[5px] flex-col w-[100%] justify-center items-center'
      style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center' }}
    >
      <input className="customInput flex "  {...getInputProps()} />
      <p className='text-white'>Drag here or click for select images</p>
    </div>
    <div className='flex overflow-y-auto scrollbar-custom2  mt-4 space-x-3 '>
        {files.map((file, index) => (
          <div key={index} className=' mb-2 rounded-[3px] min-w-[3rem]'  onClick={() => handleRemoveFile(index)}>
            <img
              src={(file as any).preview}
              alt={file.name}
              className=' h-[3rem] object-cover'
            />
      
          </div>
        ))}
      </div>
<div className='border-[1px]  mt-3 p-1 border-[#888] '>
<p className='text-[0.7rem] text-[#E3E3E3]  '>Insert a category for the thing that you are gonna ask to us</p>
</div>
<div className='flex flex-row justify-center mb-4 mt-[7rem]'>
 <motion.div
      whileHover={{ scale: 1.05 }} // Quando si passa sopra, l'elemento aumenta di dimensione
      transition={{ type: "spring", stiffness: 400, damping: 50 }}
    >
{loading? 
  
   <button  className='bg-[#A47040] flex  rounded-[6px] text-[0.7rem] px-6 text-center w-[7rem] items-center justify-center text-white  py-[0.4rem]' >
   <div className="loader3"></div></button>
:

<button   onClick={() => handleSubmit() }className='bg-[#A47040] rounded-[6px] text-[0.7rem] px-6 text-center text-white  py-[0.4rem]' >{inupdate? "Modify order" : "Finish order"  }<FontAwesomeIcon icon={faArrowRight} className=" text-[#ffffff] pl-2" /></button>

}
      
    </motion.div>
    </div>


</div>









    </div>



    
    </div>
  );
}

export default Createorder;


